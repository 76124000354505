import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import moment from "moment";
//Miscellaneous
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import SelectProfileModal from "../../../components/SelectProfileModal/SelectProfileModal";
import ReactLoading from "react-loading";
import { Icon } from "@iconify/react";
import Swal from "sweetalert2";

import { getCountUnreadInboxAlert } from "../../../store/ducks/inbox/actions";
import UpgradeAccountModal from "../../../components/BusinessUpgrade/UpgradeAccountModal";

import { upgradeBusinessAccount } from "../../../store/ducks/profile/actions";
import { getSystemSetting } from "../../../store/ducks/setting/actions";

class ProfileSideBar extends Component {
  constructor(props) {
    super(props);
    this.onDrop = (files) => {
      let newFilter = Object.assign({}, this.state.businessAccDetail);
      newFilter['ssm_image'] = files[0];
      // let newFiles = this.state.files
      // newFiles.push(files)
      // this.setState({ files:newFiles })
      this.setState({
        businessAccDetail: newFilter,
        formError: false,
        errorMsg: [],
        files: files,
      });
    }
    this.state = {
      msgLoading: true,
      unreadMsgCnt: 0,

      businessAccModal: false,
      readedBnf: false,
      upgradeAccForm: false,

      businessAccDetail: {
        companyName: '',
        companyNo: '',
        bankNo: '',
        ssm_image: '',
        bankId: ''
      },
      files: [],

      verifyBusinessAcc: false,
      errorMsg: [],
      activeBusinessAcc: false,

      openProfileModal: false,
    };
  }

  componentDidMount() {
    if (localStorage.getItem('accessToken')) {
      let params = {};
      params.lang_id = this.props.currentUser.data.language_id;
      this.props.getCountUnreadInboxAlert(params);

      this.props.getSystemSetting();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // console.log("[shouldComponentUpdate]",nextProps);
    if (nextProps.totalUnreadMsg !== undefined && nextProps.totalUnreadMsg !== this.props.totalUnreadMsg) {
      if (nextProps.totalUnreadMsg.responseCode === 200 && nextProps.totalUnreadMsg.msgType === "success") {
        let unreadCnt = nextProps.totalUnreadMsg.data.totalCount;
        unreadCnt = unreadCnt >= 99 ? 99 : unreadCnt;
        this.setState({
          msgLoading: false,
          unreadMsgCnt: unreadCnt
        });
      }
    }

    if (nextProps.upgrade_business_account_result !== undefined && nextProps.upgrade_business_account_result !== this.props.upgrade_business_account_result) {
      if (nextProps.upgrade_business_account_result.responseCode === 200 && nextProps.upgrade_business_account_result.msgType === "success") {
        const { t } = this.props;
        Swal.close();

        Swal.fire({
          title: t("global.success"),
          text: t("global.record-updated"),
          icon: "success",
          confirmButtonText: t("global.ok"),
        }).then((result) => {
          if (result.isConfirmed) {
            // this.props.getMemberProfile();
            window.location.reload();
          }
        });
      } else if (nextProps.upgrade_business_account_result.responseCode === 422) {
        this.setState({
          touched: true,
          // error_message: nextProps.memberProfileUpdateResult.message,
          errorMsg: (nextProps.upgrade_business_account_result.message.input_error || []),
          formError: true
        });
        Swal.close();
      }
    }

    if (nextProps.system_setting !== undefined && nextProps.system_setting !== this.props.system_setting) {
      if (nextProps.system_setting.data.responseCode === 200 && nextProps.system_setting.data.msgType === "success") {
        // nextProps.system_setting.data.data.result.map((val) => {
        //     if(val.event === "business-account" && val.active === false){
        //       this.setState({ activeBusinessAcc: false });
        //     }else{
        //       this.setState({ activeBusinessAcc: true});
        //     }
        // });
        let businessAcc = nextProps.system_setting.data.data.result.filter((val) => val.event === "business-account");
        if (businessAcc.length > 0 && businessAcc[0]) {
          this.setState({ activeBusinessAcc: businessAcc[0].active });
        }
      }
    }

    return true;
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  toggleBusinessAccModal = () => {
    this.setState({
      businessAccModal: !this.state.businessAccModal,
      upgradeAccForm: false,
      readedBnf: false,
    })
  }

  updateBusinessAccBtn = (ctg) => {
    if (ctg === 'readed') {
      this.setState({ readedBnf: true })
    } else if (ctg === 'continue') {
      this.setState({ upgradeAccForm: true })
    } else if (ctg === 'cancel') {
      this.setState({ readedBnf: false })
    }
  }

  setBusinessAcc = (event) => {
    let newFilter = Object.assign({}, this.state.businessAccDetail);
    newFilter[event.target.id] = event.target.value;
    this.setState({
      businessAccDetail: newFilter,
      formError: false,
      errorMsg: []
    });
  }


  removeFileHandler = () => {
    this.setState({
      files: [],
      // progress: 0,
    });
  }

  submitBusinessAccountHandler = (e) => {
    e.preventDefault();

    const { t } = this.props;

    Swal.fire({
      title: t("global.confirmation"),
      text: t("global.would-you-like-to-proceed") + "?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("global.save"),
      cancelButtonText: t("global.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        let data = {};
        data = new FormData();
        data.append('company_name', this.state.businessAccDetail.companyName);
        data.append('company_reg_no', this.state.businessAccDetail.companyNo);
        data.append('bank_id', this.state.businessAccDetail.bankId);
        data.append('bank_account_number', this.state.businessAccDetail.bankNo);
        data.append('ssm_image', this.state.businessAccDetail.ssm_image);
        data.append('country_id', this.props.currentUser.data.country_id);

        this.props.upgradeBusinessAccount(data);
      }
    });
  }

  handleBankChange = (event) => {
    let newFilter = Object.assign({}, this.state.businessAccDetail);
    newFilter['bankId'] = event.id;
    // console.log(event);
    this.setState({
      businessAccDetail: newFilter,
      formError: false,
      errorMsg: []
    });
  }

  toggleProfileModal = () => {
    this.setState({
      openProfileModal: !this.state.openProfileModal
    })
  }
  closeProfileModal = () => {
    this.setState({
      openProfileModal: false
    })
  }

  render() {
    const { t } = this.props;
    let memberRegisterDate = moment(this.props.currentUser.data.register_date_time, "YYYY-MM-DD HH:mm:ss");
    let currentDateTime = moment();
    let calculateRegisterDays = currentDateTime.diff(memberRegisterDate, 'days');

    const avatar = this.imageImport(
      require.context(
        "../../../assets/images/profile/profile-icons",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );
    const icons = this.imageImport(
      require.context(
        "../../../assets/images/profile/nav-icons",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );
    const mbIcon = this.imageImport(
      require.context(
        "../../../assets/images/profile/mobile/icons",
        false,
        /\.(png|jpe?g|svg)$/
      )
    );

    let topProfileData = null;
    let verifyBusinessAccount = false;
    let businessAccountStatus = false;
    if (!this.state.loading) {
      topProfileData = (this.props.topProfileData && this.props.topProfileData.data);
      verifyBusinessAccount = (this.props.topProfileData && this.props.topProfileData.data.topProfile.verify_business_account);
      businessAccountStatus = (this.props.topProfileData && this.props.topProfileData.data.topProfile.business_account_status);
    }

    let files = this.state.files.map(file => (
      <li key={file.name}>
        <p>
          {file.name} - {Math.round(file.size / 1024)} KB{" "}
          <button className="btn icon" onClick={this.removeFileHandler}>
            <Icon icon="mdi:close" />
          </button>
        </p>
      </li>
    ));

    const pi = window.location.pathname === "/personal-information";
    const ve = window.location.pathname === "/vip-exclusive";
    const bn = window.location.pathname === "/bonus";
    const mw = window.location.pathname === "/my-wallet";
    const th = window.location.pathname === "/transaction-history";
    const bh = window.location.pathname === "/betting-history";
    const msg = window.location.pathname === "/messages";
    const rf = window.location.pathname === "/referral";

    return (
      <Auxiliary>
        <div className="ps-col">
          <div className="ps-tp-panel">
            <div className="ps-user-details">
              <div className="ps-user-profile" onClick={this.toggleProfileModal}>
                {topProfileData ? (
                  <img src={(topProfileData && topProfileData.topProfile.profile_picture) || avatar["male-7.jpg"]} alt="profile-img" />
                ) : (
                  <div className="react-loading">
                    <ReactLoading
                      type={"spinningBubbles"}
                      color={"#fff"}
                    />
                  </div>
                )}
                {/* <div className="overlay">
                  <img src={icons["edit-icon.svg"]} alt="edit" />
                </div> */}
                <img
                  className='edit-icon'
                  src={mbIcon["edit-btn.svg"]}
                  alt="edit"
                  onClick={this.toggleShowProfilePic}
                />
              </div>

              <div className="ps-username my-2">
                <p>{this.props.currentUser.data.username}</p>
                <span className="vip-icon">
                <img src={avatar[`vip-${this.props.currentUser.data.user_group_level}.webp`]} alt="vip-badge"/>
                  {/* {t(`vip.vip-${this.props.currentUser.data.user_group_level}`)} */}
                </span>
              </div>
              <p>{t("profile-sidebar.joining-r77-for")} {calculateRegisterDays} {t("datepicker.day")}</p>
            </div>


            {/* <div className="ps-upgrade-acc mt-3">
              {verifyBusinessAccount === true && (
                <div className={`upgrade-acc-container ${businessAccountStatus === true ? "active" : ""}`} onClick={businessAccountStatus === true ? '' : this.toggleBusinessAccModal}>
                  <div className="upgrade-acc-icon">
                    <Icon icon="material-symbols:business-center" />
                  </div>
                  <p>{t("profile.business-acc")}</p>
                </div>
              )}
              <p className="km-btn">{t("global.click-to-know-more")}</p>
            </div> */}


            <ul className="navbar-nav mt-2">
              <li className="nav-item">
                <NavLink className="nav-link" to={{ pathname: "/deposit" }}>
                  <img src={icons["deposit-ico.webp"]} alt="deposit-ico" />
                  {t("profile-sidebar.deposit")}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to={{ pathname: "/withdrawal" }}>
                  <img src={icons["withdrawal-ico.webp"]} alt="withdrawal-ico" />
                  {t("profile-sidebar.withdraw")}
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="ps-bt-panel">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink className="nav-link" to={{ pathname: "/personal-information" }}>
                  <img src={icons[pi ? "pi-ico-active.svg" : "pi-ico.svg"]} alt="pi-ico" />
                  <p>{t("page-header.personal-information")}</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to={{ pathname: "/vip-exclusive" }}>
                  <img src={icons[ve ? "vip-ico-active.svg" : "vip-ico.svg"]} alt="vip-ico" />
                  <p>{t("page-header.vip-exclusive")}</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to={{ pathname: "/bonus" }}>
                  <img src={icons[bn ? "bonus-ico-active.svg" : "bonus-ico.svg"]} alt="bonus-ico" />
                  <p>{t("profile-sidebar.bonus")}</p>
                  {/* <div className="badge">8</div> */}
                </NavLink>
              </li>
              <hr />
              <li className="nav-item">
                <NavLink className="nav-link" to={{ pathname: "/my-wallet" }}>
                  <img src={icons[mw ? "wl-ico-active.svg" : "wl-ico.svg"]} alt="wl-ico" />
                  <p>{t("profile-sidebar.my-wallet")}</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to={{ pathname: "/transaction-history" }}>
                  <img src={icons[th ? "th-ico-active.svg" : "th-ico.svg"]} alt="th-ico" />
                  <p>{t("page-header.transaction-history")}</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to={{ pathname: "/betting-history" }}>
                  <img src={icons[bh ? "bh-ico-active.svg" : "bh-ico.svg"]} alt="bh-ico" />
                  <p>{t("page-header.betting-history")}</p>
                </NavLink>
              </li>
              {/* <hr />
              <li className="nav-item">
                <NavLink className="nav-link" to={{ pathname: "/x33mall" }}>
                  <img src={icons["mall-ico.svg"]} alt="mall-ico" />
                  <p>{t("profile-sidebar.x33-mall")}</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to={{ pathname: "/redemption-history" }}>
                  <img src={icons["rh-ico.svg"]} alt="rh-ico" />
                  <p>{t("profile-sidebar.redemption-history")}</p>
                </NavLink>
              </li> */}
              <hr />
              <li className="nav-item">
                <NavLink className="nav-link" to={{ pathname: "/messages" }}>
                  <img src={icons[msg ? "msg-ico-active.svg" : "msg-ico.svg"]} alt="msg-ico" />
                  <p>{t("message.message")}</p>
                  {(() => {
                    if (!this.state.msgLoading) {
                      if (this.state.unreadMsgCnt > 0) {
                        return <div className="badge">{this.state.unreadMsgCnt}</div>
                      }
                      else {
                        return null;
                      }
                    }
                  })()}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to={{ pathname: "/referral" }}>
                  <img src={icons[rf ? "rf-ico-active.svg" : "rf-ico.svg"]} alt="rf-ico" />
                  <p>{t("profile-sidebar.referral")}</p>
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink className="nav-link" to={{ pathname: "/feedback" }}>
                  <img src={icons["fb-ico.svg"]} alt="fb-ico" />
                  <p>{t("profile-sidebar.feedback")}</p>
                </NavLink>
              </li> */}
            </ul>
          </div>
        </div>
        <SelectProfileModal
          openProfileModal={this.state.openProfileModal}
          toggleProfileModal={this.toggleProfileModal}
          closeProfileModal = {this.closeProfileModal}
        />
        <UpgradeAccountModal
          {...this.state}
          files={files}
          onDrop={this.onDrop}
          removeFileHandler={this.removeFileHandler}
          setBusinessAcc={this.setBusinessAcc}
          toggleBusinessAccModal={this.toggleBusinessAccModal}
          updateBusinessAccBtn={this.updateBusinessAccBtn}
          verifyBusinessAcc={verifyBusinessAccount}
          businessAccountStatus={businessAccountStatus}
          submitBusinessAccountHandler={this.submitBusinessAccountHandler}
          handleBankChange={this.handleBankChange}
        />


      </Auxiliary>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser,
    totalUnreadMsg: state.inbox.count_unread_inbox_alert_data,
    topProfileData: state.profile.top_profile_data,
    upgrade_business_account_result: state.profile.upgrade_business_account_result,
    system_setting: state.setting.system_setting
  }
}

const mapDispatchToProps = {
  getCountUnreadInboxAlert,
  upgradeBusinessAccount,
  getSystemSetting
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withNamespaces("translation")(ProfileSideBar)));
