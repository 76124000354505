import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next';

//Miscellaneous
import { isDesktop } from "react-device-detect";
// import moment from "moment-timezone";
import Skeleton from "react-loading-skeleton";
// import Timer from "react-compound-timer";
import { Fade } from "react-awesome-reveal";

//Components
import GamePagination from "../../../components/Pagination/GamePagination";
import NoRecords from "../../../components/NoRecords/NoRecords";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";

//Icons
import { Icon } from "@iconify/react";

class FifaWorldCup extends Component 
{
    constructor(props) 
    {
        super(props);
        this.state = {}
    }

    componentDidMount() 
    {
        if(!this.props.disableSearchButton)
        {
            const listingArr = this.props.getFilterArray('fifa_world_cup');
            this.props.funcGetList(listingArr);
        }
    }

    imageImport = (r) => 
    {
        let images = {};
        r.keys().map((item) => 
        {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });

        return images;
    }

    render() 
    {
        const { t } = this.props;
        const flag = this.imageImport(require.context("../../../assets/images/world-cup/flag", false, /\.(png|jpe?g|svg|webp)$/));
        // console.log(this.props.currentUser.currency);
        return (
            <Auxiliary>
            {
                isDesktop ? (
                    <>
                        {this.props.data.loading === true
                        ?
                            <Skeleton count={2} height={'3.125rem'} style={{ marginBottom: ".25rem" }} />
                        :
                        <>
                            {this.props.data.loading === false && this.props.data.totalRecords <= 0
                            ?
                                <NoRecords component={''} />
                            :
                                <Fade duration={1000} triggerOnce>
                                    <table className="pd-table rh-pd-table">
                                    {
                                        this.props.data && this.props.data.data.map((item, index) => 
                                        {
                                            let strContent = [];

                                            strContent.push(
                                                <>
                                                    <td>
                                                        <div className='fifa-tbody'>
                                                            <div className='fifa-tcontent'>
                                                                <img src={flag[`${item.teamA.toLowerCase()}.svg`]} alt="" /><br />
                                                                <h6>{t(`world-cup.team.${item.teamA.toLowerCase()}`)}
                                                                {
                                                                    (item.teamAScore !== null && item.teamBScore !== null)
                                                                    ?
                                                                        (Number(item.teamAScore) === Number(item.teamBScore))
                                                                        ?
                                                                            null
                                                                        :
                                                                            (Number(item.teamAScore) > Number(item.teamBScore)) ? <span className="green-font">(W)</span> : <span className="red-font" >(L)</span>
                                                                    :
                                                                        null   
                                                                }
                                                                </h6>
                                                            </div>
                                                            <h6>{t('redemption-history.vs')}</h6>
                                                            <div className='fifa-tcontent'>
                                                                <img src={flag[`${item.teamB.toLowerCase()}.svg`]} alt="" /><br />
                                                                <h6>{t(`world-cup.team.${item.teamB.toLowerCase()}`)}
                                                                {
                                                                    (item.teamAScore !== null && item.teamBScore !== null)
                                                                    ?
                                                                        (Number(item.teamBScore) === Number(item.teamAScore))
                                                                        ?
                                                                            null
                                                                        :
                                                                            (Number(item.teamBScore) > Number(item.teamAScore)) ? <span className="green-font">(W)</span> : <span className="red-font" >(L)</span>
                                                                    :
                                                                        null   
                                                                }
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>                                                        
                                                        {(item.isExtra === 1) ? <h4 className='mb-0'>{item.teamAExtraScore} - {item.teamBExtraScore} </h4>
                                                        : <> 
                                                        {(item.teamAScore && item.teamBScore === 0) ? <span className='orange-font'>({t('world-cup.pending-result')})</span> :<h4 className='mb-0'> {item.teamAScore} - {item.teamBScore}</h4> }
                                                        </>}
                                                        {item.isExtra >= 1 && <span className='aet-font'>({t('world-cup.aet')})</span>}
                                                    </td>
                                                    <td>
                                                        {(item.isExtra === 2) ? <>{item.teamAExtraScore} - {item.teamBExtraScore}</> : '-'}
                                                    </td>
                                                    <td>{t(`world-cup.team.${item.bet}`)}</td>
                                                    <td>
                                                        {(item.winAmount !== null) ? <span className={(item.winAmount > 0) ? 'green-font' : 'red-font'}>{this.props.currentUser.currency} {item.winAmount}</span> : '-'}
                                                    </td>
                                                </>
                                            );

                                            return (
                                                <tbody key={index} className="rdp-his-body">
                                                    <tr className="rdp-his-title text-center">
                                                        <td className="d-flex align-items-center">
                                                            <Icon icon="ic:outline-event-note" width="1rem" height="1rem" />
                                                            <p className="ms-2">{item.date}</p>
                                                        </td>
                                                        <td>{t('world-cup.score')}</td>
                                                        <td>{t('world-cup.penalties')}</td>
                                                        <td>{t('redemption-history.your-bet')}</td>
                                                        <td>{t('redemption-history.payout')}</td>
                                                    </tr>
                                                    <tr className="rdp-his-content text-center">
                                                        {strContent}
                                                    </tr>
                                                </tbody>
                                            );
                                        })
                                    }

                                        
                                    {/* {fifaWorldCup && fifaWorldCup.map((item, index) =>
                                        <tbody key={index} className="rdp-his-body">
                                            <tr className="rdp-his-title text-center">
                                                <td className="d-flex align-items-center">
                                                    <Icon icon="ic:outline-event-note" width="1rem" height="1rem" />
                                                    <p className="ms-2">2{item.date}</p>
                                                </td>
                                                <td>{t('world-cup.score')}</td>
                                                <td>{t('world-cup.penalties')}</td>
                                                <td>Your Bet</td>
                                                <td>Win Amount</td>
                                            </tr>
                                            <tr className="rdp-his-content text-center">
                                                <td>
                                                    <div className='fifa-tbody'>
                                                        <div className='fifa-tcontent'>
                                                        <img src={flag[`${item.teamA}.svg`]} alt="" /><br />
                                                        <h6>{t(`world-cup.team.${item.teamA}`)}{(item.teamAScore) > (item.teamBScore) ? <span className="green">(W)</span> : <span className="red" >(L)</span>}</h6>
                                                        </div>
                                                        <h6>vs</h6>
                                                        <div className='fifa-tcontent'>
                                                        <img src={flag[`${item.teamB}.svg`]} alt="" /><br />
                                                        <h6>{t(`world-cup.team.${item.teamB}`)}{(item.teamBScore) > (item.teamAScore) ? <span className="green" >(W)</span> : <span className="red" >(L)</span>}</h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <h4 className='mb-0'>{item.teamAScore} - {item.teamBScore}</h4>{item.isExtra}
                                                    // <span className='orange'>Pending for Result</span>
                                                </td>
                                                <td>{item.teamAExtraScore} - {item.teamBExtraScore}</td>
                                                <td>{t(`world-cup.team.${item.yourBet}`)}</td>
                                                <td>
                                                    <span className="green" >MYR {item.winAmount}</span>
                                                    // <span className= "red" >MYR {item.winAmount}</span> 
                                                </td>
                                            </tr>
                                        </tbody>
                                    )} */}
                                    </table>
                                </Fade>
                            }
                            <GamePagination
                                totalPage={this.props.data.totalPage}
                                curPage={this.props.data.activePage}
                                data={this.props.data.data}
                                navigateList={(event, type, page, tab) => this.props.navigateList(event, type, page, 'fifa_world_cup')}
                            />
                        </>}
                    </>
                ) : (
                    <div className="pf-m-dashboard">
                        <div className="overlay" onClick={() => this.setState({ filterShow: '' })} />
                        {this.props.data.loading === true
                        ?
                            <div className="pd-m-card-col white">
                                <div className='py-3'><Skeleton count={2} height={'3.125rem'} style={{ marginBottom: ".25rem" }} /></div>
                            </div>
                        :
                            <div className="pd-m-card-col">
                            {
                                (this.props.data.loading === false && this.props.data.totalRecords <= 0)
                                ?
                                    <NoRecords component={''} />
                                :
                                <>
                                    {
                                        this.props.data && this.props.data.data.map((item, index) => 
                                        {
                                            let strContent = [];

                                            strContent.push(
                                                <>
                                                    <div className='rh-m-md-panel fifa-tbody mt-3'>
                                                        <div className='fifa-tcontent'>
                                                            <img src={flag[`${item.teamA}.svg`]} alt="" /><br />
                                                            <h6>{t(`world-cup.team.${item.teamA.toLowerCase()}`)}
                                                            {
                                                                (item.teamAScore !== null && item.teamBScore !== null)
                                                                ?
                                                                    (Number(item.teamAScore) === Number(item.teamBScore))
                                                                    ?
                                                                        null
                                                                    :
                                                                        (Number(item.teamAScore) > Number(item.teamBScore)) ? <span className="green-font">(W)</span> : <span className="red-font" >(L)</span>
                                                                :
                                                                    null   
                                                            }
                                                            </h6>
                                                        </div>
                                                        <h6>{t('redemption-history.vs')}</h6>
                                                        <div className='fifa-tcontent'>
                                                            <img src={flag[`${item.teamB}.svg`]} alt="" /><br />
                                                            <h6>{t(`world-cup.team.${item.teamB.toLowerCase()}`)}
                                                                {
                                                                    (item.teamAScore !== null && item.teamBScore !== null)
                                                                    ?
                                                                        (Number(item.teamBScore) === Number(item.teamAScore))
                                                                        ?
                                                                            null
                                                                        :
                                                                            (Number(item.teamBScore) > Number(item.teamAScore)) ? <span className="green-font">(W)</span> : <span className="red" >(L)</span>
                                                                    :
                                                                        null   
                                                                }
                                                                </h6>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="rh-m-btm-panel">
                                                        <div className='rh-m-btm-status'>
                                                            <p>
                                                                {t('world-cup.score')} : {(item.isExtra === 1) ? <>{item.teamAExtraScore} - {item.teamBExtraScore} </>
                                                                 : (item.teamAScore && item.teamBScore === 0 ?  <span className='orange'>({t('world-cup.pending-result')})</span> 
                                                                 :<>{item.teamAScore} - {item.teamBScore}</>)}
                                                                {item.isExtra >= 1 && <>({t('world-cup.aet')})</>}
                                                            </p>
                                                        </div>
                                                        <div className='rh-m-btm-status'>
                                                            <p>{t('redemption-history.your-bet')} : {t(`world-cup.team.${item.bet}`)}</p>
                                                        </div>
                                                    </div>
                                                    <div className="rh-m-btm-panel">
                                                        <div className='rh-m-btm-status'>
                                                        <p className='mt-2'>{t('world-cup.penalties')} : {(item.isExtra === 2) ? <>{item.teamAExtraScore} - {item.teamBExtraScore}</> : null}</p>
                                                        </div>
                                                        <div className='rh-m-btm-status'>
                                                        <p>
                                                            {t('redemption-history.payout')} : {(item.winAmount !== null) ? <span className={(item.winAmount > 0) ? 'green-font' : 'red-font'}>{this.props.currentUser.currency} {item.winAmount}</span> : null}
                                                        </p>
                                                        </div>
                                                    </div>
                                                </>
                                            );

                                            return (
                                                <div key={index} className='rh-m-pd-card'>
                                                    <div className="rh-m-tp-panel">
                                                        <Icon icon="ic:outline-event-note" width="1rem" height="1rem" />
                                                        <p>{item.date}</p>
                                                    </div>
                                                    {strContent}
                                                </div>
                                            );
                                        })
                                    }
                                </>
                            }
                            </div>
                        }
                    </div>
                )
            }
            </Auxiliary>
        )
    }
}

export default (withNamespaces("translation")(FifaWorldCup));