import React, { Component } from "react";
import { connect } from "react-redux";

//Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getVIP, validateVIPBirthdayBonus } from "../../store/ducks/vip/actions";
import { getMemberProfile } from "../../store/ducks/profile/actions";
import { withNamespaces } from "react-i18next";
import { isDesktop } from "react-device-detect";
import Skeleton from "react-loading-skeleton";
import Slider from "react-slick";
import Swal from "sweetalert2";
import moment from "moment";

//Components
// import Banner from "../../components/Banner/Banner";
import LoadingPage from "../../components/LoadingPage/LoadingPage";
import MobileVip from "./MobileVip";
import { currency } from "../../common/config/currency";

//Icons
import { Icon } from "@iconify/react";

class Vip extends Component {
    constructor(props) {
        super(props);
        let vipDetails = JSON.parse(localStorage.getItem('vipDetails'));
        this.state = {
            oldSlide: 0,
            activeSlide: 0,
            activeSlide2: 0,
            vip: (vipDetails && vipDetails.vip) ? vipDetails.vip : [],
            set_birthday_date: false,
            loading: true,
            login: localStorage.getItem("accessToken") ? true : false
        };
    }

    fetchData() {
        if (localStorage.getItem('vipDetails')) {
            let vipDetails = JSON.parse(localStorage.getItem('vipDetails'));
            let tmpMoment = moment(vipDetails.expired);
            let duration = moment.duration(tmpMoment.diff(moment())).asSeconds();

            if (duration > 0) {
                this.setState({
                    vip: vipDetails.vip,
                    loading: false
                });

                return false;
            }
        }

        const showVIPData = {};
        this.props.getVIP(showVIPData);
    }

    componentDidMount() {
        this.fetchData();

        if (localStorage.getItem("accessToken")) {
            this.props.getMemberProfile();

            this.props.validateVIPBirthdayBonus();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.vip_data !== this.props.vip_data) {
            if (nextProps.vip_data.responseCode === 200) {
                this.setState({
                    vip: nextProps.vip_data.data,
                    loading: false,
                });

                let expired = moment().add(2, 'minutes');
                let tmpArr = {};
                tmpArr.vip = nextProps.vip_data.data;
                tmpArr.expired = expired.format('YYYYMMDDTHHmmss');
                localStorage.setItem('vipDetails', JSON.stringify(tmpArr));
            }
        }

        if (nextProps.memberProfileData !== this.props.memberProfileData && nextProps.memberProfileData !== undefined) {
            if (nextProps.memberProfileData.responseCode === 200) {
                let memberProfile = nextProps.memberProfileData.data.memberProfile[0];

                this.setState({
                    set_birthday_date: memberProfile.dateOfBirth !== null ? true : false,
                });
            }
        }

        if (nextProps.validate_vip_birthday_bonus !== undefined && nextProps.validate_vip_birthday_bonus !== this.props.validate_vip_birthday_bonus) {
            if (nextProps.validate_vip_birthday_bonus.responseCode === 200 && nextProps.validate_vip_birthday_bonus.msgType === "success") {
                this.setState({
                    dob: nextProps.validate_vip_birthday_bonus.data.dob,
                });
            }
        }

        return true;
    }

    redirectProfilePage = () => {
        if (localStorage.getItem("accessToken")) {
            window.location.href = "/personal-information";
        } else {
            const { t } = this.props;
            Swal.fire({
                iconHtml: !isDesktop ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : "",
                icon: "info",
                title: t("global.sorry"),
                text: t("global.login-proceed"),
                confirmButtonText: t("global.ok"),
                showCancelButton: !isDesktop ? false : "",
                customClass: !isDesktop
                    ? {
                        container: "swal-mobile-container sorry",
                        icon: "swal-sorry-icon",
                    }
                    : [],
            });
        }
        // this.props.history.push('/personal-information');
    };

    redirectVipExclusivePage = () => {
        if (localStorage.getItem("accessToken")) {
            window.location.href = "/vip-exclusive";
        } else {
            const { t } = this.props;
            Swal.fire({
                iconHtml: !isDesktop ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : "",
                icon: "info",
                title: t("global.sorry"),
                text: t("global.login-proceed"),
                confirmButtonText: t("global.ok"),
                showCancelButton: !isDesktop ? false : "",
                customClass: !isDesktop
                    ? {
                        container: "swal-mobile-container sorry",
                        icon: "swal-sorry-icon",
                    }
                    : [],
            });
        }
    };

    imageImport = (r) => {
        let images = {};
        r.keys().map((item) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    render() {
        const { t } = this.props;
        const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
        const banner = this.imageImport(require.context("../../assets/images/banners", false, /\.(png|jpe?g|svg|webp)$/));
        const vip = this.imageImport(require.context("../../assets/images/vip", false, /\.(gif|png|jpe?g|svg|webp|webm)$/));

        let vipInfo = [];
        let customizedVip = this.state.vip.filter(val => val.level !== 1);
        customizedVip.map((vipval, idx) => {
            vipInfo.push({
                id: idx,
                name: t(`vip.vip-${vipval.level}`),
                img: "vip-" + (vipval.level - 1) + "",
                deposit: new Intl.NumberFormat("en-US", num_format).format(vipval.minDepositTierUpgrade),
                turnover: new Intl.NumberFormat("en-US", num_format).format(vipval.minTurnoverTierUpgrade),
                relegation: new Intl.NumberFormat("en-US", num_format).format(vipval.turnoverTierMaintain),
                depositRelegation: new Intl.NumberFormat("en-US", num_format).format(vipval.depositTierMaintain),
            });
            return null;
        });

        const vipSettings = {
            slidesToShow: isDesktop ? 1 : 3,
            adaptiveHeight: false,
            centerMode: true,
            centerPadding: isDesktop ? "392px" : "0",
            // focusOnSelect: true,
            infinite: true,
            dots: this.state.loading ? false : true,
            arrows: false,
            customPaging: function (i) {
                var i = i +1 ;
                return <span>{i < 6 && <span>{t(`vip.vip-${i + 1 }`)}</span>}</span>;
              
            },
            beforeChange: (current, next) => this.setState({ oldSlide: current, activeSlide: next }),
            afterChange: (current) => this.setState({ activeSlide2: current }),
        };

        let prevPage = this.props.location && this.props.location.state && this.props.location.state.prev ? this.props.location.state.prev : undefined;


        let imageLanguage = '';
        switch (this.props.currentUser.language_code) {
          case "zh-CN":
            imageLanguage = "-cn"
            break;
          case "ms":
            imageLanguage = "-ms"
            break;
          case "id":
            imageLanguage = "-id"
            break;
          default:
            imageLanguage = ""
            break;
        }

        return (
            <Auxiliary>
                {this.state.loading && <LoadingPage />}
                {isDesktop ? (
                    <section className="vip-section">
                        <div className="banner-col">
                            <div className="banner-item">
                                <img lassName="w-100 h-auto" src={banner[`promo-vip-banner${imageLanguage}.webp`]} alt="R77 Vip" />
                            </div>
                        </div>
                        <div className="x-container-no-m">
                            <div className="vip-slick mb-5">
                                <div className="slick-container">
                                    {this.state.loading ? (
                                        <Slider ref={(c) => (this.slider = c)} {...vipSettings}>
                                            <Skeleton count={1} height={217} width="100%" />
                                            <Skeleton count={1} height={217} width="100%" />
                                            <Skeleton count={1} height={217} width="100%" />
                                        </Slider>
                                    ) : (
                                        <Slider ref={(c) => (this.slider = c)} {...vipSettings}>
                                            {vipInfo &&
                                                vipInfo.map((item, index) => (
                                                    <div className="vip-card-container" key={index}>
                                                        <span className="card-shadow">
                                                            <img
                                                                src={
                                                                    vip["vip-bg.webp"]
                                                                }
                                                                alt="vip-img"
                                                            />
                                                        </span>
                                                        <div className="vip-card">
                                                            <div className="vip-level">
                                                                <LazyLoadImage
                                                                    className="vip-logo"
                                                                    src={vip[`${item.img}${this.state.activeSlide2 === item.id ? ".webp" : ".webp"}`]}
                                                                    alt="vip-logo"
                                                                    effect="blur"
                                                                />
                                                                <small className="mt-2 text-white">
                                                                    {/* {t("vip.vip-level")}: */}
                                                                     {item.name}
                                                                </small>
                                                            </div>
                                                            <div className="vip-info">
                                                                <div className="d-flex mb-2">
                                                                    <img className="info-icon" src={vip["t-deposit.webp"]} alt="vip-deposit" />
                                                                    <div className="vip-content">
                                                                        <p>
                                                                            {t("vip.total-deposit")}
                                                                            <span>&nbsp; ({this.state.login ? this.props.currentUser.currency : currency[this.props.currentUser.country_id]})</span>
                                                                        </p>
                                                                        <p className="amount">{item.deposit}</p>
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex mb-2">
                                                                    <img src={vip["t-turnover.webp"]} alt="vip-turnover" />
                                                                    <div className="vip-content">
                                                                        <p>
                                                                            {t("vip.total-turnover")}
                                                                            <span>&nbsp; ({this.state.login ? this.props.currentUser.currency : currency[this.props.currentUser.country_id]})</span>
                                                                        </p>
                                                                        <p className="amount">{item.turnover}</p>
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex">
                                                                    <img src={vip["relegation.webp"]} alt="vip-relagation" />
                                                                    <div className="vip-content">
                                                                        <p>
                                                                            {t("vip.relegation-turnover")}
                                                                            {/* <span>&nbsp; ({this.state.login ? this.props.currentUser.currency : currency[this.props.currentUser.country_id]})</span> */}
                                                                        </p>
                                                                        {item.depositRelegation <= 0 ? (
                                                                            <p className="amount">
                                                                                {item.depositRelegation}
                                                                                 {/* <span>{t("vip.lifetime-day")}</span> */}
                                                                            </p>
                                                                        ) : (
                                                                            <p className="amount">
                                                                                {item.depositRelegation} <span>(90 {t("datepicker.day")})</span>
                                                                            </p>
                                                                        )
                                                                        }
                                                                        {/* <span>**{t("vip.total-relegation-turnover")}：</span>

                                                                        <p className="t-relegation">{item.relegation}</p> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                        </Slider>
                                    )}
                                    <div className="slick-arrow">
                                        <div className="slick-arrow-btn prev" onClick={() => this.slider.slickPrev()}>
                                            <Icon icon="uil:angle-left-b" />
                                        </div>
                                        <div className="slick-arrow-btn next" onClick={() => this.slider.slickNext()}>
                                            <Icon icon="uil:angle-right-b" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br /><br />                            
                            <div className="vip-table mb-5">
                            <h3 className="text-center">{t("vip.vip-levels-privileges")}</h3>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <td>{t("vip.vip-level")}</td>
                                            {customizedVip.map((vipVal, idx) => {
                                                return (
                                                    <>
                                                        <td>
                                                            <div className="up-icon">
                                                            <img className="vip-icon-small" src={vip[`vip${vipVal.level}.webp`]} alt="vip-badge"/>
                                                            {t(`vip.vip-${vipVal.level}`)}
                                                            </div>
                                                        </td>
                                                    </>
                                                );
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{t('vip.valid-rollover-req')}</td>
                                            {customizedVip.map((vipVal, idx) => {
                                                return (
                                                    <td>{vipVal.minTurnoverTierUpgrade}</td>
                                                );
                                            })}
                                        </tr>
                                        <tr>
                                            <td>{t('vip.tier-validity')}</td>
                                            {customizedVip.map((vipVal, idx) => {
                                                return (
                                                    <td>{vipVal.depositTierMaintain <= 0 ? t("vip.lifetime") : '90 ' + t("datepicker.day")}</td>
                                                );
                                            })}
                                        </tr>
                                        <tr>
                                            <td colSpan={6}>{t('vip.red-packet-tnc')}</td>
                                        </tr>
                                        <tr>
                                            <td>{t('vip.required-deposit')} ({this.state.login ? this.props.currentUser.currency : currency[this.props.currentUser.country_id]})</td>
                                            {customizedVip.map((vipVal, idx) => {
                                                return (
                                                    <td>{vipVal.minDepositTierUpgrade}</td>
                                                );
                                            })}
                                        </tr>
                                        <tr>
                                            <td>{t('vip.weekly-red-packet')}</td>
                                            {customizedVip.map((vipVal, idx) => {
                                                return (
                                                    <td>{vipVal.vipWeeklybonus}</td>
                                                );
                                            })}
                                        </tr>
                                        <tr>
                                            <td>{t('vip.tailored-cs-agent')}</td>
                                            <td>-</td>
                                            <td>-</td>
                                            <td>-</td>
                                            <td><img src={vip["correct-ico.webp"]} alt="checked-icon"/></td>
                                            <td><img src={vip["correct-ico.webp"]} alt="checked-icon"/></td>
                                        </tr>
                                        <tr>
                                            <td>{t('vip.deposit-withdrawal-priority')}</td>
                                            <td>-</td>
                                            <td>-</td>
                                            <td><img src={vip["correct-ico.webp"]} alt="checked-icon"/></td>
                                            <td><img src={vip["correct-ico.webp"]} alt="checked-icon"/></td>
                                            <td><img src={vip["correct-ico.webp"]} alt="checked-icon"/></td>
                                        </tr>
                                        <tr>
                                            <td>{t('vip.withdrawal-limit')}</td>
                                            {customizedVip.map((vipVal, idx) => {
                                                return (
                                                    <td>{vipVal.maxDailyWithdrawal}</td>
                                                );
                                            })}
                                        </tr>
                                        <tr>
                                            <td>{t('vip.promotion-bonus')}</td>
                                            {customizedVip.map((vipVal, idx) => {
                                                return (
                                                    <td>{vipVal.upLevelBonus}</td>
                                                );
                                            })}
                                        </tr>
                                        <tr>
                                            <td>{t('vip.birthday-bonus-small')}</td>
                                            {customizedVip.map((vipVal, idx) => {
                                                return (
                                                    <td>{vipVal.birthdayBonus}</td>
                                                );
                                            })}
                                        </tr>
                                        <tr>
                                            <td>{t('vip.live-casino-rebate')}</td>
                                            {customizedVip.map((vipVal, idx) => {
                                                return (
                                                    <td>{vipVal.live_casino_rebate}%</td>
                                                );
                                            })}
                                        </tr>
                                        <tr>
                                            <td>{t('vip.sport-rebate')}</td>
                                            {customizedVip.map((vipVal, idx) => {
                                                return (
                                                    <td>{vipVal.sportRebate}%</td>
                                                );
                                            })}
                                        </tr>
                                        <tr>
                                            <td>{t('vip.slot-rebate')}</td>
                                            {customizedVip.map((vipVal, idx) => {
                                                return (
                                                    <td>{vipVal.slotRebate}%</td>
                                                );
                                            })}
                                        </tr>
                                        <tr>
                                            <td>{t('vip.tailored-program')}</td>
                                            <td>-</td>
                                            <td>-</td>
                                            <td>-</td>
                                            <td>-</td>
                                            <td><img src={vip["correct-ico.webp"]} alt="checked-icon"/></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h4 className="text-center">{t("vip.term-condition")}</h4>
                            <div className="vip-bullet-content mb-5">

                                <ol>
                                    <li>
                                        <div className="tnc-list ">
                                            <span className="tnc-title">{t("vip.tc-upgrade-bonus")} </span>
                                            <span>{t("vip.tc-upgrade-bonus-content")}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="tnc-list ">
                                            <span>{t("vip.tc-content-2")}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="tnc-list ">
                                            <span className="tnc-title">{t("vip.tc-birthday-bonus")} </span>
                                            <span>{t("vip.tc-birthday-bonus-content")}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="tnc-list ">
                                            <span>{t("vip.tc-content-4")}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="tnc-list ">
                                            <span>{t("vip.tc-content-5")}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="tnc-list ">
                                            <span>{t("vip.tc-content-6")}</span>
                                        </div>
                                    </li>
                                    
                                    <li>
                                        <div className="tnc-list ">
                                            <span>{t("vip.tc-content-7")}</span>
                                        </div>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </section>
                ) : (
                    <MobileVip
                        redirectVipExclusivePage={this.redirectVipExclusivePage}
                        redirectProfilePage={this.redirectProfilePage}
                        prevPage={prevPage}
                        imageLanguage={imageLanguage}
                        currency={currency}
                        customizedVip={customizedVip}
                        {...this.state}
                        {...this.props}
                    />
                )}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { vip, profile } = state;

    return {
        vip_data: vip.vip_data,
        currentUser: state.currentUser.data,
        memberProfileData: profile.member_profile_data,
        validate_vip_birthday_bonus: vip.validate_vip_birthday_bonus,
    };
};

const mapDispatchToProps = {
    getVIP,
    getMemberProfile,
    validateVIPBirthdayBonus,
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(Vip));
