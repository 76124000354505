import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
//Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { Icon } from "@iconify/react";
import { isDesktop } from "react-device-detect";
import Slider from "react-slick";
import { getSystemSetting } from "../../store/ducks/setting/actions";
// import Draggable from "react-draggable";

// import Modal from "react-modal";
// const settingMapping = {
//   "gold-digger-process": { key: "bet-and-earn", url: "/bet-and-earn" },
//   "ls-status": { key: "lucky-spin", url: "/lucky-spin" },
//   "daily-mission": { key: "check-in", url: "/check-in" },
//   leaderboard: { key: "leaderboard", url: "/leaderboard" },
//   "world-cup-event": { key: "fifa1", url: "/fifa-world-cup" },
// };

export class RightFixedButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      showGIF: false,
      openBackupLink: false,
      showEventGif: true,
      activeEventSetting: [],

      dragging: false,
    };
  }

  componentDidMount() {
    this.props.getSystemSetting();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.system_setting !== undefined && nextProps.system_setting !== this.props.system_setting) {
      if (nextProps.system_setting.data.responseCode === 200 && nextProps.system_setting.data.msgType === "success") {
        this.setState({
          activeEventSetting: nextProps.system_setting.data.data.result,
        });
      }
    }
    return true;
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  handleGifClose = () => {
    this.setState({
      showGIF: false,
    });
  };

  scrollToTopHandler = () => {
    window.scrollTo(0, 0);
  };

  //set session storage
  closeEventGif = () => {
    sessionStorage.setItem("hideEventGif", true);
    this.setState({
      showEventGif: false,
    });
  };

  // onClickGif = (event) => {
  //   console.log(event.target.id);
  //   if (event.target.id === "gif-close-btn") {
  //     // this.closeEventGif();
  //   } else {
  //     for (const item in settingMapping) {
  //       if (settingMapping[item]["key"] === event.target.id) {
  //         // window.location.replace(settingMapping[item]["url"]);
  //       }
  //     }
  //   }
  // };

  onDragGif = () => {
    this.setState({ dragging: true });
  };

  // onStopGif = (...args) => {
  //   const { dragging } = this.state;
  //   this.setState({ dragging: false });
  //   if (!dragging) {
  //     this.onClickGif(...args);
  //   }
  // };
  render() {
    const { t } = this.props;

    const icons = this.imageImport(require.context("../../assets/images/icons", false, /\.(gif|png|jpe?g|svg|webm)$/));

    let iconLanguage = "";
    switch (this.props.currentUser.language_code) {
      case "zh-CN":
        iconLanguage = "-cn";
        break;
      case "id":
        iconLanguage = "-id";
        break;
      case "ms":
        iconLanguage = "-ms";
        break;
      default:
        iconLanguage = "";
        break;
    }

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      // autoplay: true,
      autoplaySpeed: 2500,
      arrows: false,
    };

    return (
      <Auxiliary>
        <div className="right-fixed-button">
          {isDesktop && (
            <>
              {/* <a href="https://x33win.com/" target="_blank" rel="noreferrer" className="btn btn-white">
                <img src={icons["x33-movie-icon.svg"]} alt="r77-movie" />
                <p>{t("global.x33-movie")}</p>
              </a> */}
              {/* <Link className="btn btn-white" to={{ pathname: "/download-app" }}>
                <img src={icons["r77-app.svg"]} alt="r77-app" />
                <p>APP</p>
              </Link> */}
              <button type="button" onClick={window["liveChatButton"]} rel="noreferrer" className="btn btn-white">
                <img src={icons["contact-us-icon.svg"]} alt="r77-contact-us" />
                {/* <p>{t("contact-us.live-chat")}</p> */}
              </button>
            </>
          )}
          {isDesktop && (
            <button type="button" onClick={this.scrollToTopHandler} className="btn back-to-top">
              <img src={icons["back-to-top.svg"]} alt="r77-back-to-top"/>
              {/* <Icon icon="uil:angle-up" className="mb-1" /> */}
              {/* <p>{t("global.back-to-top")}</p> */}
            </button>
          )}
        </div>

        {this.state.activeEventSetting.length > 0 && (
          <div className="right-fixed-button" style={{ minHeight: "50%" }}>
            {/* <Draggable onDrag={this.onDragGif} onStop={this.onStopGif}> */}
            {/* <div className="gif-btn-container">
              {this.state.showEventGif && !sessionStorage.getItem("hideEventGif") && (
                <>
                  {(() => {
                    let activateBtn = false;
                    let activeEvent = this.state.activeEventSetting;
                    let stagingEvent = ["ls-status"];
                    let noGifEvent = ["business-account", "auto-rebate", "auto-stakings", "event-payout-process", "fifa-qna", "mall-reward", "first-bet-bonus", "secret-pay-auto-withdrawal","auto-referral"];
                    activeEvent.map((val) => {
                      if (
                        (stagingEvent.includes(val.event) && val.active && process.env.REACT_APP_ENV === "staging") ||
                        (val.active && !noGifEvent.includes(val.event))
                      ) {
                        activateBtn = true;
                      }
                      return null;
                    });

                    if (activateBtn) {
                      return (
                        <button className="btn" type="button" onClick={this.closeEventGif}>
                          <img src={icons["close-btn.svg"]} id="gif-close-btn" alt="close-btn" />
                        </button>
                      );
                    }
                  })()}
                  <Slider {...settings}>
                    {(() => {
                      let active = [];
                      let cur = this.state.activeEventSetting;
                      let stagingOnly = ["ls-status"];
                      cur.map((val, index) => {
                        let url = val.url;
                        let forwardSlash = url.indexOf("/");
                        if(forwardSlash !== 0){
                          url = '/' + val.url;
                        }
                        if (val.gif) {
                          let imageLink = iconLanguage === "" ? val.image_link : val.image_link.replace('.gif', `${iconLanguage}.gif`);
                          if (
                            stagingOnly.includes(val.event) &&
                            val.active &&
                            process.env.REACT_APP_ENV === "staging"
                          ) {
                            active.push(
                              <div className="gif-btn" key={index}>
                                <Link
                                  to={{
                                    pathname: `${url}`,
                                  }}
                                >
                                  <span className="gif-img">
                                    <img
                                      //src={icons[`${settingMapping[val.event]["key"]}${iconLanguage}.gif`]}
                                      src={imageLink}
                                      id={`${val.event}`}
                                      alt={`${val.event}`}
                                    />
                                  </span>
                                </Link>
                              </div>
                            );
                          } else {
                            if (val.active) {
                              active.push(
                                <div className="gif-btn" key={index} id={`${val.event}`}>
                                  <Link
                                    to={{
                                      pathname: `${url}`,
                                    }}
                                  >
                                    <span className="gif-img">
                                      <img
                                        //src={icons[`${settingMapping[val.event]["key"]}${iconLanguage}.gif`]}
                                        src={imageLink}
                                        id={`${val.event}`}
                                        alt={`${val.event}`}
                                      />
                                    </span>
                                  </Link>
                                </div>
                              );
                            }
                          }
                        }
                        return null;
                      });

                      return active;
                    })()}
                  </Slider>
                </>
              )}
            </div> */}
            {/* </Draggable> */}
          </div>
        )}
      </Auxiliary>
    );
  }
}

const mapStateToProps = (state) => {
  // const { vip, profile } = state;

  return {
    currentUser: state.currentUser.data,
    system_setting: state.setting.system_setting,
  };
};

const mapDispatchToProps = {
  getSystemSetting,
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(RightFixedButton));
