import React, { Component } from "react";

//Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";
import { isDesktop } from "react-device-detect";
import { LazyLoadImage } from "react-lazy-load-image-component";
//Components
// import Banner from "../../components/Banner/Banner";
import Slider from "react-slick";
// import LoadingPage from "../../components/LoadingPage/LoadingPage";
import MobileAboutUs from "./MobileAboutUs";
import BannerImage from "../../assets/images/banners/about-us-banner.webp";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";

const productCtg = [
  { id: 1, icon: "lc-icon", activeIcon: "lc-icon-active", lable: "live-casino" },
  { id: 2, icon: "sports-icon", activeIcon: "sports-icon-active", lable: "sports" },
  { id: 3, icon: "slot-icon", activeIcon: "slot-icon-active", lable: "slots" },
  { id: 4, icon: "game-icon", activeIcon: "game-icon-active", lable: "games" },
  { id: 5, icon: "p2p-icon", activeIcon: "p2p-icon-active", lable: "p2p" },
  { id: 6, icon: "lottery-icon", activeIcon: "lottery-icon-active", lable: "lottery" },
];

export class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCtg: 1,
      activePage: 1,
      activeAnimation: true,
    };
  }

  componentDidMount() { }

  toggleActiveCtg = (id) => {
    this.removeAnimation(id);
    setTimeout(() => {
      this.setState({ activePage: id, activeAnimation: true });
    }, 1000);
  };

  removeAnimation = (id) => {
    this.setState({
      activeAnimation: false,
      activeCtg: id,
    });
  };

  ProductContent = (props) => {
    const settings = {
      arrows: false,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      cssEase: "linear",
    };
    const { t } = this.props;
    return (
      <>
        {/* <LazyLoadImage
          className={`product-img ${props.activeAnimation === true ? "fade-in-left" : "fade-out-left"
            }`}
          key={props.img[props.bigImg]}
          src={props.img[props.bigImg]}
          alt="sport-img"
          effect="blur"
        /> */}
        <img
          className={`product-img ${props.activeAnimation === true ? "fade-in-left" : "fade-out-left"
            }`}
          key={props.img[props.bigImg]}
          src={props.img[props.bigImg]}
          alt="sport-img"
        />
        <div className="product-content">
          <div
            className={`${props.activeAnimation === true
              ? "fade-in-right"
              : "fade-out-right"
              }`}
          >
            <h1>{props.title}</h1>
            <p>{props.content}</p>
            <p className="mt-4">{t("global.providers")}:</p>
            {props.provider.length >= 6 ? (
              <Slider {...settings}>
                {props.provider &&
                  props.provider.map((item, index) => (
                    <span className="card-shadow" key={index}>
                      <img src={props.icon[item]} alt={item} />
                    </span>
                  ))}
              </Slider>
            ) : (
              <ul className="provider-list gap-2 justify-content-center">
                {props.provider &&
                  props.provider.map((item, index) => (
                    <li key={index}>
                      <span className="card-shadow">
                        <img src={props.icon[item]} alt={item} />
                      </span>
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </div>
      </>
    );
  };

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;
    const img = this.imageImport(
      require.context(
        "../../assets/images/about-us",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );
    const icon = this.imageImport(
      require.context("../../assets/images/icons", false, /\.(png|jpe?g|svg|webp)$/)
    );

    const banner = this.imageImport(require.context("../../assets/images/banners", false, /\.(png|jpe?g|svg|webp)$/));

    let imageLanguage = '';
    switch (this.props.currentUser.language_code) {
      case "zh-CN":
        imageLanguage = "-cn"
        break;
      case "ms":
        imageLanguage = "-ms"
        break;
      case "id":
        imageLanguage = "-id"
        break;
      default:
        imageLanguage = ""
        break;
    }
    return (
      <Auxiliary>
        {isDesktop ? (
          <>
            <div className="about-us-banner">
              {/* Todo: update require if added */}
              {/* <img className="w-100 h-auto" src={BannerImage} alt=""/> */}
              <img className="w-100 h-auto" src={banner[`about-us-banner${imageLanguage}.webp`]} alt="R77 about us" />
              <div className="section-header">
                <h2 className="no-banner">{t("about-us.about-us")}</h2>
                <p className="w-65">
                  {t("about-us.about-us-details")}
                </p>
              </div>
            </div>

            <section className="global-section-two">
              <div className="mission-bg">
                <div className="x-container">
                  <div className="mission-section">
                    <span className="card-shadow">
                      <img src={img["mission-bg.webp"]} alt="mission-bg" />
                    </span>
                    <LazyLoadImage
                      className="section-two-img"
                      src={img["mission-img.webp"]}
                      alt="mission-img"
                      effect="blur"
                    />
                    <img
                      className="asset-1"
                      src={img["dice-img.webp"]}
                      alt="spade-img"
                    />
                    <div className="section-two-content">
                      <h1>{t("about-us.our-mission")}</h1>
                      <p>
                        {t("about-us.our-mission-details-1")}
                        <br />
                        <br />
                        {t("about-us.our-mission-details-2")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            
            <div className="product-bg">
              <section className="product-section">
                <div className="x-container">
                  <div className="section-header">
                    <h2 className="mb-2">{t("about-us.product-overview")}</h2>
                    <p>
                      {t("about-us.product-overview-details")}
                    </p>
                  </div>

                  <div className="row">
                    <div className="col" style={{ zIndex: 1 }}>
                      <span className="card-shadow">
                        <img
                          className="sideBar-bg"
                          src={img["side-bar-bg.webp"]}
                          alt="sidebar background"
                        />
                      </span>
                      <ul className="product-sideBar-container gap-3">
                        {productCtg &&
                          productCtg.map((item, index) => (
                            <li
                              key={index}
                              className={`${item.id === this.state.activeCtg ? "active" : ""
                                }`}
                              onClick={() => this.toggleActiveCtg(item.id)}
                              style={{
                                pointerEvents: ` ${this.state.activeAnimation === false
                                  ? "none"
                                  : ""
                                  }`,
                              }}
                            >
                              <div className="product-sideBar">
                                <span>
                                  <img
                                    className="ctg-icon"
                                    src={item.id === this.state.activeCtg ? icon[`${item.activeIcon}.webp`] : icon[`${item.icon}.webp`]}
                                    alt={item.icon} 
                                  />
                                </span>
                                <p>{t(`about-us.${item.lable}`)}</p>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>

                    <div className="col">
                      <span className="card-shadow">
                        <img
                          src={img["mission-bg.webp"]}
                          alt="product-bg"
                          style={{ height: 430 }}
                        />
                      </span>
                      {this.state.activePage === 1 ? (
                        <this.ProductContent
                          img={img}
                          icon={icon}
                          bigImg="lc-img.webp"
                          title={t("about-us.live-casino-title")}
                          content={t("about-us.live-casino-content")}
                          provider={[
                            "evo-icon.svg",
                            "pp-icon.svg",
                            "ag-icon.svg",
                            "pt-icon.svg",
                            "gp-icon.svg",
                            "dg-icon.svg",
                            "ab-icon.svg",
                            "mg-icon.svg",
                            "sa-icon.svg",
                            "sbo-icon.svg",
                            // "ea-icon.svg",
                          ]}
                          activeAnimation={this.state.activeAnimation}
                        />
                      ) : this.state.activePage === 2 ? (
                        <this.ProductContent
                          img={img}
                          icon={icon}
                          bigImg="sport-img.webp"
                          title={t("about-us.sports-title")}
                          content={t("about-us.sports-content")}
                          provider={["sb-icon.svg", "sbo-icon.svg"]}
                          activeAnimation={this.state.activeAnimation}
                        />
                      ) : this.state.activePage === 3 ? (
                        <this.ProductContent
                          img={img}
                          icon={icon}
                          bigImg="slot-img.webp"
                          title={t("about-us.slots-title")}
                          content={t("about-us.slots-content")}
                          provider={[
                            // "ib-icon.svg",
                            "pp-icon.svg",  
                            "pt-icon.svg",
                            "mg-icon.svg",
                            "sg-icon.svg",
                            "ag-icon.svg",
                            "gp-icon.svg",
                            "qs-icon.svg",
                            "net-icon.svg",
                            "png-icon.svg",
                            "qt-icon.svg",
                            "evo-icon.svg",
                            "sp-icon.svg",
                            "ttg-icon.svg",
                            "sbo-icon.svg",
                          ]}
                          activeAnimation={this.state.activeAnimation}
                        />
                      ) : this.state.activePage === 4 ? (
                        <this.ProductContent
                          img={img}
                          icon={icon}
                          bigImg="games-img.webp"
                          title={t("about-us.games-title")}
                          content={t("about-us.games-content")}
                          provider={[
                            // "ib-icon.svg",
                            "pt-icon.svg",
                            "pp-icon.svg",  
                            "mg-icon.svg",
                            "evo-icon.svg",
                            "net-icon.svg",
                            "png-icon.svg",
                            "qt-icon.svg",
                            "sb-icon.svg",
                            "sbo-icon.svg",
                            "sg-icon.svg",
                            "sp-icon.svg",
                          ]}
                          activeAnimation={this.state.activeAnimation}
                        />
                      ) : this.state.activePage === 5 ? (
                        <this.ProductContent
                          img={img}
                          icon={icon}
                          bigImg="p2p-img.webp"
                          title={t("about-us.p2p-title")}
                          content={t("about-us.p2p-content")}
                          provider={[
                            "gp-icon.svg", 
                            // "sg-icon.svg"
                          ]}
                          activeAnimation={this.state.activeAnimation}
                        />
                      ) : this.state.activePage === 6 ? (
                        <this.ProductContent
                          img={img}
                          icon={icon}
                          bigImg="lottery-img.webp"
                          title={t("about-us.lottery-title")}
                          content={t("about-us.lottery-content")}
                          provider={[
                            "gp-icon.svg",                            
                            "sb-icon.svg", 
                            "qt-icon.svg"
                          ]}
                          activeAnimation={this.state.activeAnimation}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </section>

              <section className="global-section-two">            
                <div className="x-container">
                  <div className="customer-section">
                    <span className="card-shadow">
                      <img src={img["mission-bg.webp"]} alt="customer-bg" />
                    </span>
                    <LazyLoadImage
                      className="section-two-img"
                      src={img["customer-img.webp"]}
                      alt="customer-img"
                      effect="blur"
                    />
                    <div className="section-two-content">
                      <h1>{t("about-us.customer-care-1")}</h1>
                      <h2>{t("about-us.customer-care-2")}</h2>
                      <br />
                      <p>
                        {t("about-us.customer-care-details")} {""}
                        <Link to={{ pathname: "/contact-us"}}>                 
                          {t("about-us.live-chat")}                  
                        </Link> {""} 
                        {t("about-us.and")} {""}
                        <Link to={{ pathname: "/contact-us"}}>
                          {t("about-us.email")}
                        </Link>.
                      </p>
                    </div>
                  </div>
                </div>           
              </section>
            </div>
          </>
        ) : (
          <MobileAboutUs />
        )}
      </Auxiliary>
    );
  }
}

const mapStateToProps = (state) => {
  const { vip, profile } = state;

  return {
      vip_data: vip.vip_data,
      currentUser: state.currentUser.data,
      memberProfileData: profile.member_profile_data,
      validate_vip_birthday_bonus: vip.validate_vip_birthday_bonus,
  };
};


export default connect(mapStateToProps)(withNamespaces("translation")(AboutUs));
