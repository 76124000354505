import React, { Component, createRef } from 'react';
import { withNamespaces } from "react-i18next";
import { Icon } from "@iconify/react";
import { Fade } from "react-awesome-reveal";
import Timer from "react-compound-timer";
import Confetti from 'react-confetti';
import Skeleton from "react-loading-skeleton";
import Slider from "react-slick";
import Button from "../../components/Button/Button";
import SideBar from '../../components/SideBar/SideBar';
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";

class MobileFifaPremierLeague extends Component 
{
    constructor(props) 
    {
        super(props);
        this.topSection = createRef();
    }

    scrollToTop = () => 
    {
        this.topSection.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    imageImport = (r) => 
    {
        let images = {};
        r.keys().map((item) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    }

    render() 
    {
        const { t } = this.props;
        const banner = this.imageImport(require.context("../../assets/images/banners", false, /\.(png|jpe?g|svg|webp)$/));
        // const img = this.imageImport(require.context("../../assets/images/leaderboard", false, /\.(png|jpe?g|svg)$/));
        const image = this.imageImport(require.context("../../assets/images/world-cup", false, /\.(png|jpe?g|svg|webp)$/));
        const flag = this.imageImport(require.context("../../assets/images/world-cup/epl-flag", false, /\.(png|jpe?g|svg|webp)$/));

        let imageLanguage = '';
        switch (this.props.currentUser.language_code) 
        {
            case "zh-CN":
                imageLanguage = "-cn";
            break;
            case "ms":
              imageLanguage = "-ms"
              break;
              case "indon":
                imageLanguage = "-indon"
                break;
            default:
                imageLanguage = "";
            break;
        }

        let settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: true,
            swipeToSlide:  false, 
            initialSlide: (this.props.input.slideCount || 0),
            arrows: false,
        };
        
        let dateList = this.props.dropDown.dateList ? this.props.dropDown.dateList : [];
        let month = this.props.dropDown.month ? this.props.dropDown.month : [];
        let match = this.props.dropDown.match ? this.props.dropDown.match : [];
        let fakeData = [];
        let tcDataTBody = [];

        const updatedData = month.map(item => {
            let mthLabelDate = item.date.split('-');
            let mthLabelYearMonth = `${mthLabelDate[0]}${t('premier-league.year')} ${mthLabelDate[1]}${t('premier-league.months')}`;
            return {
                ...item,
                labelYearMonth: mthLabelYearMonth, 
            };
        });
        month = updatedData;

        if(this.props.totalMatch[this.props.input.date] && this.props.totalMatch[this.props.input.date].count > 0)
        {
            let fakeAmt = (this.props.dropDown.reward && this.props.dropDown.reward.prize) ? this.props.dropDown.reward.prize : 0;
            let fakeAmt2 = fakeAmt;
            let initialAmt = fakeAmt;
            let fakeCount = (this.props.totalMatch[this.props.input.date].count > 4) ? 4 : this.props.totalMatch[this.props.input.date].count;
            // let fakeCount = (this.props.totalMatch[this.props.input.date].count > 0) ? this.props.totalMatch[this.props.input.date].count : 0;
            for (let i = 1; i <= fakeCount; i++) 
            {
                fakeData.push(
                    <div className={`wc-reward-container active col-4 `} key={i}>
                        <div className='wc-reward-header'>
                            <p className='m-0'>{t('premier-league.modal-msg', {attribute: i})}</p>
                        </div>
                        <div>
                            <img src={image[`reward-${i}.png`]} alt={`reward-${i}`} />
                            <p>{fakeAmt}</p>
                        </div>
                    </div>
                );
                fakeAmt = fakeAmt + initialAmt;

                tcDataTBody.push(
                    <tr>
                        <td>{t('premier-league.guess', {attribute: i})}</td>
                        <td>{fakeAmt2}</td>
                    </tr>
                );
                fakeAmt2 = fakeAmt2 + initialAmt;
            }
        }

        return (
            <Auxiliary>
                <SideBar />
                <div className="banner-col" >
                    <div className="banner-item">
                        <img src={banner[`epl-banner${imageLanguage}.webp`]} alt="R77 Promos" />
                    </div>
                </div>
                <section className='pl-section' ref={this.topSection}>
                    <div className='section-header-gray mt-3 mb-0'>
                        <h1>{t('premier-league.title')}</h1>
                        <h5>{t('premier-league.title-1')}</h5>
                    </div>
                    <div className='lb-section'>
                        <div className='lb-event-date mt-2'>
                            {/* <button className='btn btn-gradient-blue'>
                                <Icon icon="uil:angle-left" />
                            </button>
                            <div className='date'>
                                <Icon icon="uil:clock" />
                                <Fade>
                                <span>2022年11月23日</span>
                                <span className='status active'>进行中</span>
                                </Fade>
                            </div>
                            <button className='btn btn-gradient-blue'>
                                <Icon icon="uil:angle-right" />
                            </button> */}

                            {
                                (this.props.input.month !== '') 
                                ?
                                    month.length > 0 && month.map((item, index) => (
                                        (item.date === this.props.input.month) 
                                        ? 
                                            <>
                                                <button className='btn btn-gradient-blue' onClick={() => this.props.changeDateHandler('', month[((index - 1) < 0) ? (month.length - 1) : (index - 1)].date)}>
                                                    <Icon icon="uil:angle-left" />
                                                </button>
                                                <div className='date'>
                                                    <span>{item.labelYearMonth}</span>
                                                </div>
                                                <button className='btn btn-gradient-blue' onClick={() => this.props.changeDateHandler('', month[((index + 1) === month.length) ? 0 : (index + 1)].date)}>
                                                    <Icon icon="uil:angle-right" />
                                                </button>
                                            </>
                                        : 
                                            null
                                    ))
                                :
                                    null
                            }
                        </div>
                    </div>
                    <div className='wc-category-cont'>
                                    <div className='slick-container'>
                                        {this.props.fistLoading ? (
                                            <Skeleton count={1} height={40} style={{ marginBottom: "1rem" }} />
                                        ) : (
                                            <Slider ref={c => (this.slider = c)} {...settings}>
                                                {dateList.length > 0 && dateList.map((item, index) => (
                                                    <div className="match-date-btn" key={index}>
                                                    <button type="button"
                                                        className={`lb-game ${this.props.input.date === item.name && 'active'}`}
                                                        onClick={() => this.props.changeDateHandler(item.name)}
                                                    >
                                                        <small>{item.labelDay}</small>
                                                        <h3 className='mb-0'>{item.labelDate}</h3>
                                                        <p className={`status ${item.status}`}>{item.annouce}</p>
                                                    </button>
                                                    </div>
                                                ))}
                                            </Slider>
                                        )}
                                        <div className="slick-arrow">
                                            <div className="slick-arrow-btn prev" onClick={() => this.slider.slickPrev()}>
                                                <Icon icon="uil:angle-left-b" />
                                            </div>
                                            <div className="slick-arrow-btn next" onClick={() => this.slider.slickNext()}>
                                                <Icon icon="uil:angle-right-b" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    <div className='mb-5'>
                        <form className="" method="post" id="wc_form" encType='multipart/form-data' onSubmit={this.props.submitHandler} noValidate>
                            <div className='wc-vs-section card-shadow wc-table mt-5 text-center'>
                                <div className='section-header-date'>
                                    {/* <h3>
                                        {t('premier-league.title-2', 
                                            {
                                                attribute: ((this.state.totalMatch[this.state.input.date] && this.state.totalMatch[this.state.input.date].slug && t(`premier-league.${this.state.totalMatch[this.state.input.date].slug}`)) || ''),
                                                year: displayYear
                                            }
                                        )}
                                    </h3> */}
                                    {(this.props.totalMatch[this.props.input.date] 
                                    && this.props.totalMatch[this.props.input.date].disabled 
                                    && this.props.totalMatch[this.props.input.date].totalWinCount > 0)
                                    ? 
                                        (<h4 className='result-text'>{t('premier-league.congrats-msg-3')}<span className='orange-text'>{this.props.totalMatch[this.props.input.date].totalWinCount}</span>{t('premier-league.congrats-msg-4')}</h4>)
                                    :
                                        <p className='event-date'>{t('premier-league.remain-time')}：
                                            {this.props.input.date && this.props.input.date !== '' && !this.props.loading && 
                                                <Timer 
                                                    initialTime={this.props.getDuration()}
                                                    direction="backward"
                                                    formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}
                                                >
                                                    <Timer.Days />{t('premier-league.d')} <Timer.Hours />{t('premier-league.h')} <Timer.Minutes />{t('premier-league.min')} <Timer.Seconds />{t('premier-league.s')}
                                                </Timer>
                                            }
                                        </p>
                                    }
                                    <h5 className='mt-3'>
                                        {this.props.totalMatch[this.props.input.date] && !this.props.totalMatch[this.props.input.date].disabled &&
                                            (<>{t('premier-league.submit-msg', {attribute: (this.props.totalMatch[this.props.input.date] && this.props.totalMatch[this.props.input.date].count) || 0})}</>)
                                        }
                                    </h5>
                                </div>
                                <div className='wc-head row gy-3'>
                                    {match.length > 0 &&
                                    <>
                                        {match.map((item, index) => 
                                        {
                                            if(item.date === this.props.input.date)
                                            {
                                                return (
                                                    <div className='wc-body' key={index}>
                                                        <Fade duration={1000} triggerOnce>
                                                                <div className='wc-content'>
                                                                <div className='wc-time'> 
                                                                    <p>{t('premier-league.start-time')}：{item.label}</p>
                                                                    </div>
                                                                    <div className='wc-details'>
                                                                        <div className={`form-check form-check-inline`}>
                                                                            <input
                                                                                className="btn-check"
                                                                                type="radio"
                                                                                name={`matchOpt${item.teamAId}`}
                                                                                id={`matchOpt${item.teamAId}`}
                                                                                value={`${item.id}_${item.teamAId}`}
                                                                                onChange={
                                                                                    (this.props.totalMatch[this.props.input.date] && !this.props.totalMatch[this.props.input.date].disabled)
                                                                                    ?
                                                                                    (event) => this.props.onInputChangeHandler(event)
                                                                                    :
                                                                                    null
                                                                                }
                                                                                checked={
                                                                                    (this.props.input.matchArr[item.id]
                                                                                    && this.props.input.matchArr[item.id] !== undefined
                                                                                    && Number(this.props.input.matchArr[item.id]) === Number(item.teamAId))
                                                                                    || (Number(this.props.input.matchBetArr[item.id]) === Number(item.teamAId))
                                                                                    ? 
                                                                                    true 
                                                                                    : 
                                                                                    false
                                                                                }
                                                                                // disabled={
                                                                                //     (this.props.input.matchBetArr[item.id] || this.props.totalMatch[this.props.input.date].disabled)
                                                                                //     ? 
                                                                                //     true
                                                                                //     :
                                                                                //     false
                                                                                // }
                                                                            />
                                                                            <label className={`btn form-check-label ${(Number(item.teamAScore) < Number(item.teamBScore)) ? 'disabled' : ''}`} htmlFor={`matchOpt${item.teamAId}`}>
                                                                                <img src={flag[`${item.teamA.toLowerCase()}.svg`]} alt={item.teamA} />
                                                                                <p>{t(`premier-league.team.${item.teamA.toLowerCase()}`)}</p>
                                                                            </label>
                                                                            {(Number(item.teamAScore) > Number(item.teamBScore))
                                                                            // {((Number(item.teamAScore) > Number(item.teamBScore)) || ((Number(item.teamAExtraScore) > Number(item.teamBExtraScore))))
                                                                            ?
                                                                                <img className="win-img" src={image["win-2.png"]} alt={`win${item.teamA}`} />
                                                                            :
                                                                                null
                                                                            }
                                                                        </div>

                                                                        {/* <div className={(item.teamAScore !== null && item.teamBScore !== null) ? `versus-body` : ''}>
                                                                            <div className={(item.teamAScore !== null && item.teamBScore !== null) ? `mb-2` : ''}>
                                                                                <img className='versus-img' src={image["versus.png"]} alt="vs" />
                                                                            </div>
                                                                            {(item.teamAScore !== null && item.teamBScore !== null) 
                                                                            ? 
                                                                                <>
                                                                                    <span>{t('premier-league.score')}</span>
                                                                                    <span className='btn-white'>
                                                                                        {item.teamAScore} - {item.teamBScore}
                                                                                    </span>
                                                                                </>
                                                                            :
                                                                                null
                                                                            }
                                                                        </div> */}
                                                                        <div>
                                                                                <h5 className='mt-2 flex-start fw-bold'>VS</h5>
                                                                            </div>

                                                                        {/* <div className='versus-body'>
                                                                            <span>总得分</span>
                                                                            <span className='score-font'>2 - 2</span>
                                                                            <span className='aet-font'>(a.e.t)</span>
                                                                            <span className='mt-2'>Penalties</span>
                                                                            <span className='btn-gradient-blue'>3 - 2</span>
                                                                        </div> */}

                                                                        <div className={`form-check form-check-inline`}>
                                                                            <input
                                                                                className="btn-check"
                                                                                type="radio"
                                                                                name={`matchOpt${item.teamBId}`}
                                                                                id={`matchOpt${item.teamBId}`}
                                                                                value={`${item.id}_${item.teamBId}`}
                                                                                onChange={
                                                                                    (this.props.totalMatch[this.props.input.date] && !this.props.totalMatch[this.props.input.date].disabled)
                                                                                    ?
                                                                                    (event) => this.props.onInputChangeHandler(event)
                                                                                    :
                                                                                    null
                                                                                }
                                                                                checked={
                                                                                    (this.props.input.matchArr[item.id]
                                                                                    && this.props.input.matchArr[item.id] !== undefined
                                                                                    && Number(this.props.input.matchArr[item.id]) === Number(item.teamBId))
                                                                                    || (Number(this.props.input.matchBetArr[item.id]) === Number(item.teamBId))
                                                                                    ? 
                                                                                    true 
                                                                                    : 
                                                                                    false
                                                                                }
                                                                                // disabled={
                                                                                //     (this.props.input.matchBetArr[item.id] || this.props.totalMatch[this.props.input.date].disabled)
                                                                                //     ?
                                                                                //     true
                                                                                //     :
                                                                                //     false
                                                                                // }
                                                                            />
                                                                            <label className={`btn form-check-label ${(Number(item.teamBScore) < Number(item.teamAScore)) ? 'disabled' : ''}`} htmlFor={`matchOpt${item.teamBId}`}>
                                                                                <img src={flag[`${item.teamB.toLowerCase()}.svg`]} alt={item.teamB} />
                                                                                <p>{t(`premier-league.team.${item.teamB.toLowerCase()}`)}</p>
                                                                            </label>
                                                                            {(Number(item.teamBScore) > Number(item.teamAScore))
                                                                            // {((Number(item.teamBScore) > Number(item.teamAScore)) || ((Number(item.teamBExtraScore) > Number(item.teamAExtraScore))))
                                                                            ?
                                                                                <img className="win-img" src={image["win-2.png"]} alt={`win${item.teamB}`} />
                                                                            :
                                                                                null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className={(item.teamAScore !== null && item.teamBScore !== null) ? `score-wrap` : ''}>
                                                                            {(item.teamAScore !== null && item.teamBScore !== null) 
                                                                            ? 
                                                                                <>
                                                                                <div className='score-body'>
                                                                                    <span>{t('premier-league.score')}</span>
                                                                                    <span className='score-font btn-white'>
                                                                                        {/* {(item.isExtra === 1)
                                                                                        ?
                                                                                        <>{item.teamAExtraScore} - {item.teamBExtraScore} </>
                                                                                        : */}
                                                                                        <>{item.teamAScore} - {item.teamBScore} </>
                                                                                        {/* } */}
                                                                                    </span>  
                                                                                    </div>  
                                                                                <div className='score-body'>
                                                                                <span className='aet-font'>{t('premier-league.aet')}</span>
                                                                                        <span className='btn-white score-font aet-font'>{item.teamAExtraScore}-{item.teamBExtraScore}</span>
                                                                                    {/* {item.isExtra >= 1 && <span className='aet-font'>({t('premier-league.aet')})</span>}
                                                                                    
                                                                                    {item.isExtra === 2 &&
                                                                                        <><span className='mt-2'>{t('premier-league.penalties')}</span>
                                                                                        <span className='btn-gradient-blue'>{item.teamAExtraScore} - {item.teamBExtraScore}</span>
                                                                                    </>
                                                                                    } */}
                                                                                    </div>
                                                                                </>
                                                                            :
                                                                            ""
                                                                                // <div className={(item.teamAScore !== null && item.teamBScore !== null) ? `mb-3` : ''}>
                                                                                //     <img className='versus-img' src={image["versus.png"]} alt="vs" />
                                                                                // </div>
                                                                            }
                                                                        </div>
                                                                    {(item.teamAScore !== null && item.teamBScore !== null) &&
                                                                        <Confetti
                                                                            width={415}
                                                                            height={446}
                                                                            numberOfPieces={30}
                                                                            opacity={0.3}
                                                                        />
                                                                    }
                                                                </div>
                                                        </Fade>
                                                    </div>
                                                );
                                            }
                                            else
                                            {
                                                return null;
                                            }
                                        })}
                                    </>}
                                </div>
                            </div>
                            {(this.props.totalMatch[this.props.input.date] && !this.props.totalMatch[this.props.input.date].disabled)
                            ?
                                <Button
                                    typeButton={(localStorage.getItem("accessToken")) ? `submit` : `button`}
                                    classValue="btn-gradient-blue w-100 mt-3"
                                    buttonName={(localStorage.getItem("accessToken")) ? t('global.submit') : t("premier-league.login-to-submit")}
                                    clicked={(localStorage.getItem("accessToken")) ? null : () => this.props.history.push("/login")}
                                />
                            :
                                null
                            }
                        </form>
                            {/*
                        <div className='section-header-gray mt-5 mb-2'>
                            <h1>{t('premier-league.title-3')}</h1>
                            <h5>{t('premier-league.title-4')}</h5>
                        </div>
                        <div className='wc-reward row gap-3 mb-5'>
                            {fakeData}
                            */}
                            {/* {
                                this.props.fakeData.table2 && this.props.fakeData.table2.map((item, index) => (
                                    <div className={`wc-reward-container  ${item.status}`} key={index}>
                                    <div className='wc-reward-header'>
                                        <p className='m-0'>{item.value}</p>
                                    </div>
                                    <div>
                                        <img src={image[`${item.img}.png`]} alt="reward-1" />
                                        <p>{item.label}</p>
                                    </div>
                                    </div>
                                ))
                            } */}
                            {/*
                        </div>
                        */}
                    </div>
                </section>

                {/* using lb section */}

                <div className='term-section no-bg'>
                    {/* <h4 className='tnc-title'>{t('premier-league.title-6')}</h4> */}
                    <div className='term-container no-bg'>
                        {/*
                        <p>{t('premier-league.title-5')}</p>
                        <table className='table mt-2'>
                        <thead>
                            <tr>
                            <th>{t('premier-league.title-7')}</th>
                            <th>{t('premier-league.title-8')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tcDataTBody}
                        </tbody>
                        </table>
                        */}
                        <div className='term-content no-bg'>
                            <p className='mb-2'>{t('premier-league.rules')}：</p>
                            <ol>
                                <li>{t('premier-league.rule-1')}</li>
                                <li>{t('premier-league.rule-2').replace('##coin##',((this.props.dropDown.reward && this.props.dropDown.reward.coin) || 10))}</li>
                                <li>{t('premier-league.rule-3')}</li>
                                <li>{t('premier-league.rule-4')}</li>
                                <li>{t('premier-league.rule-5')}</li>
                                <li>{t('premier-league.rule-6')}</li>
                                <li>{t('premier-league.rule-7').replace('##turnover##',((this.props.dropDown.reward && this.props.dropDown.reward.turnover) || 5))}</li>
                                <li>{t('premier-league.rule-8')}</li>
                                <li>{t('premier-league.rule-9')}</li>
                                <li>{t('premier-league.rule-10')}</li>
                                <li>{t('premier-league.rule-11')}</li>
                            </ol>
                        </div>
                    </div>
                </div>

                <div className="btt-btn mt-0 mb-5">
                    <div className="btn btn-white" onClick={this.scrollToTop}>
                        <Icon icon="ph:caret-double-up-bold" />
                        {t("vip.back-to-top")}
                    </div>
                </div>
            </Auxiliary>
        )
    }
}

export default (withNamespaces("translation")(MobileFifaPremierLeague)); 