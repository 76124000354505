import React, { Component } from 'react'
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { isDesktop } from 'react-device-detect';
import Logo from "../../assets/images/brand-logo.png";
// import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import DomPurify from "../../components/Sanitize/DomPurify";
import SideBar from '../../components/SideBar/SideBar';

class MaintenancePageMobile extends Component {

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;

    const img = this.imageImport(
      require.context(
        "../../assets/images/miscellaneous",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const img2 = this.imageImport(
      require.context("../../assets/images", false, /\.(png|jpe?g|svg|webp)$/)
    );
    // const icon = this.imageImport(
    //   require.context("../../assets/images/icons", false, /\.(png|jpe?g|svg)$/)
    // );
    return (
      <Auxiliary>
        {
          isDesktop ? (
            <>
              <section className="miscellaneous desktop">
                <div className="x-container-no-m">
                  <div className="miscellaneous-content">
                    <div className="miscellaneous-body">
                      <img src={img["maintenance.webp"]} alt="maintenance" className="left-image"/>
                      </div>
                      <div className="miscellaneous-body">
                      <img src={img2["brand-logo.png"]} alt="R77 logo" className="logo-image" />                      
                      {/* <DomPurify text={t("miscellaneous.maintenance").replace("##start_date##", '2023-Feb-21 07:00AM').replace("##end_date##", '2023-Feb-21 07:30AM')} /> */}
                      <h3>{t("miscellaneous.under-maintenance")}</h3>                                                                    
                      <a className="btn enter-btn-blue" onClick={window['liveChatButton']} rel="noreferrer">
                        {t("contact-us.contact-us")}
                      </a>
                      {/* <Link className="btn enter-btn-blue"
                        to={{ pathname: "/contact-us" }}>
                        {t("contact-us.contact-us")}
                      </Link> */}
                    </div>
                  </div>
                </div>
              </section>
            </>
          ) : (
            <section className="miscellaneous mobile">
            <SideBar />
            <div className="m-container">
              <div className="miscellaneous-content">
              {/* <img src={Logo} alt="R77 logo" className="logo-image" /> */}
                <img src={img["maintenance.webp"]} alt="Maintainance" />
                {/* <p><DomPurify text={t("miscellaneous.maintenance").replace("##start_date##", '2023-Feb-21 07:00AM').replace("##end_date##", '2023-Feb-21 07:30AM')} /></p> */}
                <h3>{t("miscellaneous.under-maintenance")}</h3>                                 
                <a className="btn enter-btn-blue" onClick={window['liveChatButton']} rel="noreferrer">
                  {t("contact-us.contact-us")}
                </a>
                {/* <Link className="btn-gradient-blue"
                    to={{ pathname: "/contact-us" }}>
                    {t("contact-us.contact-us")}
                </Link> */}
              </div>
            </div>
          </section>
          )
        }
      </Auxiliary>
    )
  }
}

export default (withNamespaces("translation")(MaintenancePageMobile));