import React, { Component } from 'react';
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { Icon } from '@iconify/react';
import { withNamespaces } from "react-i18next";
import { getUserData } from "../../../store/ducks/currentUser/actions";
import { connect } from 'react-redux';
import * as actions from "../../../store/ducks/cny/actions";
import { NavLink } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import SideBar from '../../../components/SideBar/SideBar';

export class MobileCnyLuckyDraw extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventEnd: false,
        };
    }


    imageImport = (r) => {
        let images = {};
        r.keys().map((item) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    render() {
        const { t } = this.props;
        const tNc = this.imageImport(require.context("../../../assets/images/tnc", false, /\.(png|jpe?g|svg|webp)$/));
        const banner = this.imageImport(require.context("../../../assets/images/banners", false, /\.(png|jpe?g|svg|webp)$/));
        const prizeImg = this.imageImport(require.context("../../../assets/images/new-year-lucky-draw", false, /\.(png|jpe?g|svg|webp)$/));
        const tncMobile = this.imageImport(require.context("../../../assets/images/tnc/mobile", false, /\.(png|jpe?g|svg|webp)$/));


        let imageLanguage = '';
        switch (this.props.currentUser.language_code) {
            case "zh-CN":
                imageLanguage = "-cn";
                break;
            case "ms":
                imageLanguage = "-bm"
                break;
            case "indon":
                imageLanguage = "-bm"
                break;
            default:
                imageLanguage = "";
                break;
        }


        return (
            <Auxiliary>
                <SideBar />
                <div className="banner-col">
                    <div className="banner-item">
                    <img src={banner[`cny_2024${imageLanguage}.webp`]} alt="R77 Promos" />
                    </div>
                </div>

                <section className='pl-section cny'>
                    <div className='section-header-gray mt-3'>
                        <h2>{t('cny-event.title')}</h2>
                        <p>{t('cny-event.subtitle')}</p>
                        <p className='event-date'>{t('cny-event.date')}</p>
                    </div>

                    {this.props.depositAmount && localStorage.getItem("accessToken")?
                                    <div className='cny-flower-label'>
                                        <div className='mb-5 mt-5'>
                                            <div className='section-header-gray mb-0'>
                                                <h2>{t('cny-event.lucky-draw-ticket')}</h2>
                                            </div>
                                            <p className='w-75 m-auto text-center'>{t('cny-event.ticket-1')}<span>{this.props.participatedAmount}</span>{t('cny-event.ticket-2')}<span>{this.props.ticketAmount}</span>{t('cny-event.ticket-3')}</p>
                                        </div>
                                        <div className='row cny-paper-btn justify-content-center mb-5'>
                                            <div className='col-6'>
                                                <div className='cny-label'>
                                                    <h5>{t('cny-event.ticket-participated')}</h5>
                                                    <p>{this.props.participatedAmount}</p>
                                                </div>
                                            </div>
                                            <div className='col-6'>
                                                <div className='cny-label'>
                                                    <h5>{t('cny-event.ticket-balance')}</h5>
                                                    <p>{this.props.ticketAmount}</p>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center mt-5">
                                            {(localStorage.getItem("accessToken")) ? 
                                            <button
                                                className="d-flex align-items-center justify-content-center btn-gradient-blue btn w-100"
                                                onClick={this.props.claimHandler}
                                                // id="checked"
                                                disabled={this.props.buttonDisabled}
                                                ref={(ref) => (this.checkedButtonRef = ref)}
                                            >
                                                {t('cny-event.participate-now')}
                                            </button>
                                            :
                                            null
                                            }
                                        </div>

                                    </div>
                                    :
                                    ""}

                    <div className='section-header-gray mt-5 mb-3'>
                        <h2>{t('cny-event.earn-lucky')}</h2>
                        <p className='text-center'>{t('cny-event.once-time')}</p>
                    </div>
                    {this.props.depositAmount && localStorage.getItem("accessToken")?
                        <>
                            <div className='row cny-paper-btn mb-3 mobile'>
                                <div className='col-12'>
                                    <div className='cny-label'>
                                        <h5>{t('cny-event.current-deposit')}</h5>
                                        <p><span>MYR</span> {this.props.currentDeposit}</p>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className='cny-label'>
                                        <h5>{t('cny-event.current-valid')}</h5>
                                        <p><span>MYR</span> {this.props.currentValid}</p>
                                    </div>

                                </div>
                            </div>
                        </>
                        :
                        ""}
                    <Fade duration={1000} triggerOnce>
                        <div className='term-section cny'>
                            <table className='table mb-0'>
                                <thead>
                                    <tr>
                                        <th>{t('cny-event.deposit-amount')}</th>
                                        <th>{t('cny-event.valid-turnover')}</th>
                                        <th>{t('cny-event.lucky-draw')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props.drawTicketTable && this.props.drawTicketTable.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.deposit}</td>
                                                <td>{item.valid}</td>
                                                <td>{item.lucky}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <p className="text-center" style={{ color: '#3e73fa' }}>{t('cny-event.note')}<span className='fw-bold'>{t('cny-event.deposit-amount')}</span>{t('cny-event.note-1')}<span className='fw-bold'>{t('cny-event.valid-turnover')}</span>{t('cny-event.note-2')}<span className='fw-bold'>{t('cny-event.lucky-draw')}</span></p>
                    </Fade>
                    <div className="d-flex align-items-center justify-content-center mt-4">
                        {(localStorage.getItem("accessToken")) ? 
                        <button
                            className="d-flex align-items-center justify-content-center btn-gradient-blue w-100 btn"
                            onClick={this.props.claimHandler} 
                            id="checked"
                            disabled={this.props.buttonDisabled}
                            ref={(ref) => (this.checkedButtonRef = ref)} 
                        >
                            {t('norecord.deposit-now')}
                        </button>
                        : 
                            null
                        }
                    </div>

                    {this.props.eventEnd ? (
                        <>
                            <div className='d-flex align-items-center justify-content-center mb-3 mt-5'>
                                <span className='p-2'>
                                    <img className="w-100 h-auto object-fit-cover" src={prizeImg[`star.svg`]} alt="star icon" />
                                </span>
                                <div className='section-header-gray mb-0'>
                                    <h2>{t('christmas-event.lucky-winner')}</h2>
                                </div>
                                <span className='p-2'>
                                    <img className="w-100 h-auto object-fit-cover" src={prizeImg[`star.svg`]} alt="star icon" />
                                </span>
                            </div>
                            <Fade duration={1000} triggerOnce>
                                <div className='term-section christmas ' style={{ backgroundColor: 'unset' }}>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th>{t('christmas-event.rank')}</th>
                                                <th>{t('leaderboard.prize')}</th>
                                                <th>{t('leaderboard.winner')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{t('christmas-event.1st-prize')}</td>
                                                <td>{t('cny-event.myr68000')}</td>
                                                <td>A*a*w*n*77</td>
                                            </tr>
                                            <tr>
                                                <td>{t('christmas-event.2nd-prize')}</td>
                                                <td>{t('cny-event.myr38000')}</td>
                                                <td>M*h*m*a*Ah</td>
                                            </tr>
                                            <tr>
                                                <td>{t('christmas-event.3rd-prize')}</td>
                                                <td>{t('cny-event.macbook')}</td>
                                                <td>M*g*n*e*h99</td>
                                            </tr>
                                            <tr>
                                                <td>{t('cny-event.4th-prize')}</td>
                                                <td>{t('cny-event.iphone')}</td>
                                                <td>B*b*8*9*</td>
                                            </tr>
                                            <tr>
                                                <td>{t('cny-event.5th-more')}</td>
                                                <td>{t('cny-event.myr688')}</td>
                                                <td>C*o*n*a*<br />
                                                    S*e*h*n*oo<br />
                                                    B*l*y*a*23<br />
                                                    W*i*i*<br />
                                                    P*e*l*n*79<br />
                                                    M*d*a*8*</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>


                            </Fade>
                        </>

                    ) : ("")}

                    <div className='section-header-gray my-3'>
                        <h3>{t('christmas-event.prize-to-won')}</h3>
                        <p> {t('cny-event.minimun-deposit')} </p>
                    </div>

                    <div className='row text-center'>
                        <div className='col-12'>
                            <img className='w-75 h-auto object-fit-cover' src={prizeImg[`prize-1${imageLanguage}.webp`]} alt="xmas lucky draw one" />
                        </div>

                        <div className='col-6 px-0 py-3' >
                            <img className='w-100 object-fit-cover' src={prizeImg[`prize-2${imageLanguage}.webp`]} alt="xmas lucky draw one" />
                        </div>

                        <div className='col-6 px-0 py-3' >
                            <img className='w-100 object-fit-cover' src={prizeImg[`prize-3${imageLanguage}.webp`]} alt="xmas lucky draw one" />
                        </div>
                        <div className='col-6 px-0 py-3'>
                            <img className='w-100 object-fit-cover' src={prizeImg[`prize-4${imageLanguage}.webp`]} alt="xmas lucky draw one" />
                        </div>
                        <div className='col-6 px-0 py-3'>
                            <img className='w-100 object-fit-cover' src={prizeImg[`prize-5${imageLanguage}.webp`]} alt="xmas lucky draw one" />
                        </div>
                    </div>

                    <div className='term-section mobile mt-5'>
                        <div className='term-container card-shadow'>
                            <img src={tncMobile[`term-condition${imageLanguage}.svg`]} alt="Terms Condition" />
                            <div className='term-content'>
                                <ol>
                                    <li>{t('cny-event.tnc-1')}</li>
                                    <li>{t('cny-event.tnc-2')}</li>
                                    <li>{t('cny-event.tnc-3')}</li>
                                    <li>{t('cny-event.tnc-4')}<span className='fw-bold'>{t('cny-event.tnc-4-1')}</span></li>
                                    <li>{t('cny-event.tnc-5')}</li>
                                    <li>{t('cny-event.tnc-6')}</li>
                                    <li>{t('cny-event.tnc-7')}</li>
                                    <li>{t('cny-event.tnc-8')}</li>
                                    <li>{t('cny-event.tnc-9')}</li>
                                    <li>{t('cny-event.tnc-10')}</li>
                                    <li>{t('cny-event.tnc-11')}</li>
                                </ol>
                            </div>
                            <img src={tNc["term-footer.png"]} alt="Terms Condition footer" />
                        </div>
                    </div>
                </section>
            </Auxiliary>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser.data,
    };
}


const mapDispatchToProps = (dispatch) => {
    return {
        getUserData: (data) => dispatch(getUserData(data)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MobileCnyLuckyDraw));