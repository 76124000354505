import React, { Component } from 'react'
import Modal from "react-modal";
import { Icon } from "@iconify/react";
import DomPurify from "../../components/Sanitize/DomPurify";

export class PromosTncModal extends Component {

  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  }
  render() {
    const tncImg = this.imageImport(require.context("../../assets/images/tnc/mobile", false, /\.(png|jpe?g|svg|webp)$/));
    return (
      <Modal
        isOpen={this.props.openPromoTncModal}
        closeTimeoutMS={500}
        portalClassName={`global-modal`}
        className="modal modal-dialog modal-dialog-centered modal-dialog-scrollable modal-pd"
        ariaHideApp={false}
        onRequestClose={this.props.toggleTncModalHandler}>
        <div className="modal-content tnc-content">
          <div className="modal-header">
            <Icon type="button" className="btn-close" icon="mdi:close" onClick={this.props.toggleTncModalHandler} />
          </div>
          <div className="modal-body table-responsive">
            <section className="promos-table-section">
              <div className="promos-table-container p-0">
                <h2>{this.props.showPromosTitle}</h2>
                {this.props.showPromosTable !== null && (
                  <span className="card-shadow">
                    <DomPurify text={this.props.showPromosTable} />
                  </span>
                )}
              </div>
            </section>

            <section className="term-section mt-3">
              <div className="term-container p-0">
                {/* <img src={tncImg["term-condition.svg"]} alt="Terms Condition" /> */}
                <div className="term-content">
                  <DomPurify text={this.props.showPromosContent} />
                </div>
                <img src={tncImg["term-footer-m.jpg"]} alt="Terms Footer" />
              </div>
            </section>
          </div>
        </div>
      </Modal>
    )
  }
}

export default PromosTncModal