import React, { Component } from "react";
import { connect } from "react-redux";
// import DomPurify from "../../../components/Sanitize/DomPurify";
import { withNamespaces } from "react-i18next";
// import Skeleton from "react-loading-skeleton";

//Miscellaneous
// import ReactPlayer from "react-player";
// import { LazyLoadImage } from "react-lazy-load-image-component";

//Icons
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import domain from "../../../common/data/domain.json";

let dynamicCountry = "malaysia";
if (domain.id.key.includes(window.location.host)) {
  dynamicCountry = "indonesia";
}

export class ProductOverview extends Component {

  ProductOverviewType = (props) => {
    return (
      <div className="col-6">
        <Link 
          className="product-container"
          to={{ pathname: props.link}} 
        >
          <span>{props.label}</span>
          <img 
            className="product-content"
            src={props.img} 
            alt={props.alt} 
          />
          <img 
            className="product-content-hover"
            src={props.imgHover} 
            alt={props.alt} 
          />
        </Link>
      </div>
    )
  }

  constructor(props) {
    super(props);
    this.state = {
      // currentTab: 1,
      activeTab: 1,
    };
  }

  changeTab = (current, next) => {
    this.setState({
      // currentTab: next + 1,
      changingTab: true,
      activeTab: next + 1,
    });
  };

  goToSlick = (index, tab) => {
    this.slider.slickGoTo(index);
    this.setState({
      activeTab: tab,
    });
  };

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;
    // let imageLanguage = "";
    // switch (this.props.currentUser.language_code) {
    //   case "zh-CN":
    //     imageLanguage = "-cn";
    //     break;
    //   // case "ms":
    //   //   imageLanguage = "-ms"
    //   //   break;
    //   default:
    //     imageLanguage = "";
    //     break;
    // }

    const image = this.imageImport(
      require.context(
        "../../../assets/images/home",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const productImg = this.imageImport(
      require.context(
        "../../../assets/images/about-us",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const icon = this.imageImport(
      require.context(
        "../../../assets/images/icons/mobile",
        false,
        /\.(png|jpe?g|svg)$/
      )
    );

    const chinese = this.imageImport(
      require.context(
        "../../../assets/images/home/chinese",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const raya = this.imageImport(
      require.context(
        "../../../assets/images/home/raya",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const merdeka = this.imageImport(
      require.context(
        "../../../assets/images/home/merdeka",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const mooncake = this.imageImport(
      require.context(
        "../../../assets/images/home/mooncake",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const halloween = this.imageImport(
      require.context(
        "../../../assets/images/home/halloween",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const christmas = this.imageImport(
      require.context(
        "../../../assets/images/home/christmas",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const products = [
      {
        id: 1,
        icon: "lc-m",
        activeIcon: "lc-active-m",
        lable: "live-casino",
        name: "lc",
        link: `online-live-games-${dynamicCountry}`,
      },
      {
        id: 2,
        icon: "sport-m",
        activeIcon: "sport-active-m",
        lable: "sports",
        name: "sport",
        link: `online-sports-games-${dynamicCountry}`,
      },
      {
        id: 3,
        icon: "slot-m",
        activeIcon: "slot-active-m",
        lable: "slots",
        name: "slot",
        link: "slot",
      },
      {
        id: 4,
        icon: "games-m",
        activeIcon: "games-active-m",
        lable: "games",
        name: "games",
        link: "games",
      },
      {
        id: 5,
        icon: "p2p-m",
        activeIcon: "p2p-active-m",
        lable: "p2p",
        name: "p2p",
        link: "p2p",
      },
      {
        id: 6,
        icon: "lottery-m",
        activeIcon: "lottery-active-m",
        lable: "lottery",
        name: "lottery",
        link: "lottery",
      },
    ];

    const settings = {
      slidesToShow: 1,
      adaptiveHeight: false,
      centerMode: true,
      centerPadding: "392px",
      // focusOnSelect: true,
      swipe: false,
      infinite: true,
      arrows: false,
      beforeChange: (current, next) => {
        this.changeTab(current, next);
      },
      afterChange: (current) => this.setState({ activeSlide2: current }),
    };

    return (
      <section
        className={`global-section ${this.props.currentEvent === "cny" ? "cny event" : this.props.currentUser.country_id !== 4  && this.props.currentEvent === "christmas" ? "event" : ""}`}
      >
        <div className="x-container">
          <div
            className={`section-header ${this.props.currentEvent === "cny" && "cny-header"
              }`}
          >
            <div className="d-flex align-item-center">
            {this.props.currentEvent === "christmas" ? (
                                <img
                                    src={christmas["title-deco-left.webp"]}
                                    alt="header-deco"
                                />
                            ) : this.props.currentEvent === "cny" ? (
                                <img
                                    src={chinese["title-deco-left.webp"]}
                                    alt="header-deco"
                                />
                            ) :(
                                <></>
                            )}
                            {this.props.currentEvent === "cny" ?  <h2>{t("main-page.product-overview")}</h2> :  <h2 className="mb-4">{t("main-page.product-overview")}</h2> }
                            {this.props.currentEvent === "christmas" ? (
                                <img
                                    src={christmas["title-deco-right.webp"]}
                                    alt="header-deco"
                                />
                            ) : this.props.currentEvent === "cny" ? (
                                <img
                                    src={chinese["title-deco-right.webp"]}
                                    alt="header-deco"
                                />
                            ) : (
                                <></>
                            )}
            </div>
            <p>{t("main-page.product-overview-remark")}</p>
          </div>
          {/* <img class="d-block mt-4 mx-auto w-100" src={image["asset-2.png"]} alt="" /> */}
          <div className="row">
            <div className="col-6">
                <div className="row">
                  <this.ProductOverviewType 
                    link={`online-live-games-${dynamicCountry}`}
                    img={image['casino.webp']} 
                    imgHover={image['casino-hover.webp']} 
                    alt="live casino"
                    label={t('game-type.ca')}
                  />
                  <this.ProductOverviewType 
                    link={`online-sports-games-${dynamicCountry}`}
                    img={image['sports.webp']} 
                    imgHover={image['sports-hover.webp']} 
                    alt="sports"
                    label={t('game-type.sp')}
                  />
                </div>
            </div>
            <div className="col-6">
                <div className="row g-2">
                  <this.ProductOverviewType 
                    link='slot'
                    img={image['slots.webp']} 
                    imgHover={image['slots-hover.webp']} 
                    alt="slots"
                    label={t('game-type.sl')}
                  />
                  <this.ProductOverviewType 
                    link='p2p'
                    img={image['p2p.webp']} 
                    imgHover={image['p2p-hover.webp']} 
                    alt="p2p"
                    label={t('game-type.p2')}
                  />
                  <this.ProductOverviewType 
                    link='games'
                    img={image['games.webp']} 
                    imgHover={image['games-hover.webp']} 
                    alt="games"
                    label={t('game-type.ga')}
                  />
                  <this.ProductOverviewType 
                    link='lottery'
                    img={image['lottery.webp']} 
                    imgHover={image['lottery-hover.webp']} 
                    alt="lottery"
                    label={t('game-type.ke')}
                  />
                </div>
            </div>

          </div>
   
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser.data,
  };
};

export default connect(mapStateToProps)(
  withNamespaces("translation")(ProductOverview)
);
